
/////////////////////////////////////////////////
// main nav
/////////////////////////////////////////////////
.banner {
	position:relative;
	width: 100%;
	z-index:9999;
	background-color: rgba($white,0.9);
	box-shadow: 0 0 15px rgba($black,0.3);

	&.sticky {
		background: rgba($white,0.9) !important;
		animation: fading .5s ease-in-out;
		animation-fill-mode: both;

		@keyframes fading {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		.brand {
			background-image: url(../images/dawson-lupul-red-black.svg) !important;
		}

		ul#menu-main-navigation {
			li {
				a, button {
					// @include media-breakpoint-up(md) {
						color: $black;
					// }
					&:hover { color: $gray; }
				}

				&.active {
					a {
						color: $gray;
					}
				}
			}
		}
	}
	
	// .nav-main {
	.nav-wrap {
		background-color: transparent;
		border: none;
		border-radius: 0;
		padding: 0;	
		min-height: $navigation-height-sm;

		@include media-breakpoint-up(lg) {
			min-height: $navigation-height-md;
		}
		
		@include media-breakpoint-up(xl) {
			min-height: $navigation-height;
			// padding-left: calc(8.33333333vw + 0px);
			// padding-right: calc(8.33333333% + 0px);
			padding-right: 0;
			// y = mx + b; m = (y2 - y1)/(x2 - x1); b = y1 - m * x1
		}
	}

	.brand {
		margin: 0;
		position: absolute;
		top: 1px;
		// bottom:0;
		left: 50%;
		width: 100px;
		height: 59px;
		text-indent: -999em;
		background-image: url(../images/dawson-lupul-red-black.svg);
		background-repeat: no-repeat;
		background-size: cover;
		display: block;
		z-index:101;
		transform: translateX(-50px);
		
		@include media-breakpoint-up(lg) {
			margin:0;
			position:absolute;
			left:15px;
			top: 30px;
			transform: translateX(0);	
		}
		
		@include media-breakpoint-up(xl) {
			left:2.6vw;
			top:auto;
			bottom:0;
			width: 177px;
			height: 105px;	
		}
	}
}

// navigation
.navbar-header {
    float: none;
	min-height:$navigation-height-sm;

	@include media-breakpoint-up(lg) {
		float:right;
	}
}	

.navbar-collapse {
	padding:0;
	border-top:0 solid transparent;
	@include transition(all 300ms ease-in-out);

	@include media-breakpoint-down(md){
		position: absolute;
		width: 100%;
		background-color: rgba($white,0.9);
	}
}

.navbar-collapse.collapse {
	display: none!important;
	height: 0!important;
	padding-bottom: 0;
	overflow: visible!important;

	@include media-breakpoint-up(lg) {
		display: block!important;
		height: auto!important;
	}
}

.navbar-collapse.collapsing {
	display: block!important;
	background-color:transparent;

	@include media-breakpoint-down(md){
		background-color: rgba($white,0.9);
	}
}

.navbar-collapse.show {
    display: block!important;
	height:auto!important;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
	border-color:transparent;
	clear:both;
}

ul#menu-main-navigation {
	list-style:none;
	margin:0;
	padding:0;
	flex-flow:column nowrap;
	
	@include media-breakpoint-up(lg) {
		background-color:transparent;
		justify-content:flex-end;
		align-items:center;
		flex-flow:row nowrap;
	}

	li {
		float:left;
		margin:0;
		padding:0;
		position:relative;
		
		@include transition(all .25s ease-in-out);
		
		a, button {
			margin:0;
			padding:15px;
			display:block;
			font-weight: 400;
			font-size: em(16px);
			line-height: 1.618;
			color: $black;
			text-align: center;
			text-shadow: none;
			text-transform: uppercase;
			background: transparent;
			border: 0 solid $gray;
			border-width:0;
			border-radius: 0;
			text-decoration: none !important;
			overflow:hidden;
			@include transition(all .25s ease-in-out);
			
			@include media-breakpoint-up(lg) {
				display: inline-block;
				margin: 0;
				padding: 0 35px;
				font-size: em(18px);
				line-height: $navigation-height-md;
				text-align: center;
				color: $black;
				border-bottom: 0 solid $white;
			}
			
			@include media-breakpoint-up(xl) {
				font-size:em(20px);
				line-height: $navigation-height;
			}
		}

		.sub-menu {
			margin:0;
			padding: 0;
		
			@include media-breakpoint-up(sm){
				background-color:lighten($black,5%);
			}
			
			@include media-breakpoint-up(lg){
				display: none;
				position: absolute;
				// left: 0;
				left:0;
				height:0;
				z-index: 999;
				opacity:0; // http://jsfiddle.net/hashem/9dsGP/2/
				width:250px;
				background-color:rgba($white,0.9);
				@include transition(color .25s ease-in-out, background-color .25s ease-in-out, opacity 2.5s ease-in-out, box-shadow .25s ease-in-out, visibility .25s ease-in-out);
			}

			li {
				float:none;
				padding:0;
				margin:0;
				display:block;
				text-align:left;
				
				a {
					font-weight:400;
					text-transform: capitalize;
					color:$gray;
					line-height:1.2;
					
					@include media-breakpoint-up(lg){
						padding:10px 15px;
						font-size:em(16px);
						text-align:right;
						text-transform:uppercase;
						box-shadow: none !important;

						&:hover {
							color:$black !important;
						}
					}
				}
			}
			
			@include media-breakpoint-up(lg){
				li:hover {
					background-color:$gray;

					a {
						color:$black;
					}
				}
			}
		}
		
		@include media-breakpoint-up(lg){
			&:hover .sub-menu {
				display: block;
				opacity:1;
				height:auto;
			}
		}
	}
}

ul#menu-main-navigation > li:hover a, 
ul#menu-main-navigation > li > a:hover, 
ul#menu-main-navigation > li.active > a,
ul#menu-main-navigation > li > button:hover, 
ul#menu-main-navigation > li.active > button {
  	position:relative;
	background-color:transparent;
	color: $gray;

	@include media-breakpoint-up(lg){
		box-shadow:inset 0 -4px 0 $black;
	}
}

.home {
	ul#menu-main-navigation > li > a:hover, 
	ul#menu-main-navigation > li.active > a,
	ul#menu-main-navigation > li > button:hover, 
	ul#menu-main-navigation > li.active > button {
		color: $black;

		@include media-breakpoint-up(lg){
			box-shadow:inset 0 0 0 $black;
		}
	}
}

.nav>li>button:focus, .nav>li>button:hover,
.nav>li>a:focus, .nav>li>a:hover { // bootstrap override
    background-color:transparent;
}

.navbar-toggle {
	position:relative;
	float:right;
	padding:0;
	margin:20px 15px 0 0;
		display: block;
	
	@include media-breakpoint-up(md) {
	}
	@include media-breakpoint-up(lg) {
		display: none;
	}

	margin-bottom:0;
	background-color:transparent;
	background-image:none;
	border:0 solid transparent;
	border-radius:0;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
	background-color:transparent;
	color:#992916;
}

.navbar-default .navbar-toggle:focus span.glyphicon, .navbar-default .navbar-toggle:hover span.glyphicon {
	color:#992916;
}

// navburger
.hamburger {
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: $hamburger-hover-transition-duration;
	transition-timing-function: $hamburger-hover-transition-timing-function;
	// Normalize (<button>)
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	// margin: 0;
	overflow: visible;

	&:active, 
	&:focus {
		outline:0;
		border:0;
	}

	&:hover {
		@if $hamburger-hover-use-filter == true {
			filter: $hamburger-hover-filter;
		}
		@else {
			opacity: $hamburger-hover-opacity;
		}
	}
}

.hamburger-box {
	  width: $hamburger-layer-width;
	  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
	  display: inline-block;
	  position: relative;
}

.hamburger-inner {
	  display: block;
	  top: 50%;
	  margin-top: $hamburger-layer-height / -2;

	&,
	&::before,
	&::after {
		width: $hamburger-layer-width;
		height: $hamburger-layer-height;
		background-color: $hamburger-layer-color;
		border-radius: $hamburger-layer-border-radius;
		position: absolute;
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease;
	}

	&::before,
	&::after {
		content: "";
		display: block;
	}

	&::before {
		top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
	}

	&::after {
		bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
	}
}

/*
* Squeeze
*/
.hamburger--squeeze {
	.hamburger-inner {
		transition-duration: 0.1s;
		transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

		&::before {
			transition: top 0.1s 0.14s ease,
			opacity 0.1s ease;
		}

		&::after {
			transition: bottom 0.1s 0.14s ease,
			transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		}
	}

	&.is-active {
		.hamburger-inner {
			transform: rotate(45deg);
			transition-delay: 0.14s;
			transition-timing-function: cubic-bezier(0.215, 0.61, 0.55, 1);

			&::before {
				top: 0;
				opacity: 0;
				transition: top 0.1s ease,
				opacity 0.1s 0.14s ease;
			}

			&::after {
				bottom: 0;
				transform: rotate(-90deg);
				transition: bottom 0.1s ease,
				transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.55, 1);
			}
		}
	}
}

.home {

	.hamburger-inner {
		&,
		&::before,
		&::after {
			background-color: $white;
		}
	}

	ul#menu-main-navigation {
		li {
			@include media-breakpoint-up(lg) {
				a, button {
					color: $white !important;

					&:hover {
						color:$gray !important;
					}
				}
			}

			.sub-menu {
				li a { color: $black !important; }
			}
		}
	}

	.banner {
		background: -moz-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%);
		box-shadow: none;

		&.sticky {
			box-shadow: 0 0 15px rgba($black,0.3);

			.hamburger-inner {
				&,
				&::before,
				&::after {
					background-color: $black;
				}
			}

			ul#menu-main-navigation {
				li {
					a, button {
						// @include media-breakpoint-up(lg) {
							color: $black !important;
						// }
					}

					.sub-menu {
						li a { color: $black !important; }
					}
				}
			}
		}
		
		.brand {
			background-image: url(../images/dawson-lupul.svg);
		}
	}
}