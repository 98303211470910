footer.content-info {
	// margin-top:75px;
	padding:50px 0 35px;
	position:relative;
	background: $black;
	color: $gray;
	
	a {
		text-decoration:none;
		color: $white;
		
		&:hover {
			color: $red;
		}
	}
	
	.footer-row {
		
		& > div {
			margin-bottom: 35px;
			padding-bottom: 35px;
			border-bottom: 1px solid $gray;
		}
		
		&:last-of-type {
			
			& > div {
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: 0 solid $gray;
			}
		}
	}

	.footer-logo {
		margin: 50px auto 25px;
		width: 203px;
		height: 120px;
		text-indent: -999em;
		background-image: url(../images/dawson-lupul-red.svg);
		background-repeat: no-repeat;
		background-size: cover;
		display: block;
		z-index:101;

		@include  media-breakpoint-up(md){
			margin: 50px 0 0;
		}
	}
	
	.footer-tout {
		@include  media-breakpoint-down(lg){
			margin-bottom: 15px;
		}
		
	
		.webicon {
			font-size: em(36px);

			@include media-breakpoint-up(lg){
				font-size: em(48px);			
			}
		}
		
		h3 {
			margin: 0 0 0 10px;
			font-size: em(20px);
			font-weight: 300;

			@include media-breakpoint-up(lg){
				font-size: em(28px);			
			}
		}
	}
	
	h3.footer-headline {
		margin-bottom: 35px;
		font-size: em(20px);
		font-weight: 300;
		// font-style: italic;
		color: $gray;
		text-transform: uppercase;
		
		@include media-breakpoint-up(md){
			font-size: em(28px);
		}
	}
	
	#menu-footer-navigation {
		text-align:center;
		margin:0 auto;
		padding:0;

		@include media-breakpoint-up(md){
			text-align:right;
		}
		
		li {
			display:block;
			margin-left:10px;
			
			a {
				font-size: em(14px);
				text-transform: uppercase;
				color: $white;
				
				&:hover {
					color: $red;
				}
			}
		}
		
		.sub-menu {
			margin:0;
			padding:0;
			display:inline-block;
			
			li {
				display: inline-block;
			}
		}
	}
	
	.legal {
		font-size: em(12px);
		// font-family: $sans-serif;
		// color: $white;
	}
}