// general page stuff

.page-header {
	margin-top: 25px;
	
	@include media-breakpoint-up(lg){
		margin-top: 50px;
	}
}


.content-container { // override for sticky nav
	// padding-top: $navigation-height-sm;
	
	@include media-breakpoint-up(lg){
		// padding-top: $navigation-height-md;
	}
	
	@include media-breakpoint-up(xl){
		// padding-top: $navigation-height;
	}
}

// home page

.home .content-container {
	padding-top: 0; // override for sticky nav
}

.home section {
	position: relative;
	margin-bottom: 100px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

#home-intro {
	/*
	$navigation-height:115px;
	$navigation-height-md:100px;
	$navigation-height-sm:60px;
	*/
	margin-top: -$navigation-height-sm;
	height: 100vh;
	overflow: hidden;
	
	@include media-breakpoint-up(lg){
		margin-top: -$navigation-height-md;
	}
	
	@include media-breakpoint-up(xl){
		margin-top: -$navigation-height;
	}
	
	.slide-img {
		width:100vw;
		height:100vh;
		background-size: cover;
		background-position: 50% 50%;
	}
	
	.est {
		position: absolute;
		bottom: 0;
		left: 50%;
		background-color: $white;
		width: 149px;
		height:60px;
		background-image: url(../images/est-1995.svg);
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 60px 17px;
		transform: translateX(-75px); // or translate(X,Y)
		z-index: 997;
	}
}

#home-content {
	// border:1px dotted gold;

	h2 {
		margin: 0 auto 35px;
		font-size: em(30px);
		color: $black;
		
		@include media-breakpoint-up(lg){
			font-size: em(48px);
		}
	}
	
	p {
		font-size: em(24px);
		line-height: 2;

		@include media-breakpoint-up(lg){
			font-size: em(28px);
		}
	}
}

#home-featured {

	h3 {
		margin: 0;
		padding-bottom: 10px;
		font-size: em(22px);
		text-transform: uppercase;
		border-bottom: 5px solid $black;
	}

	@include media-breakpoint-down(md){
		div[class^=col-] {
			margin-bottom: 25px;
		}
	}
}

// testimonials
.testimonial {
	margin-top: 100px;
	// padding: 15px;
	padding: 30px 15px;	
	// Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+70,000000+70&0.5+0,0+70
	background: -moz-linear-gradient(top, rgba($black,0.3) 0%, rgba($black,0) 40%);
	background: -webkit-linear-gradient(top, rgba($black,0.3) 0%,rgba($black,0) 40%);
	background: linear-gradient(to bottom, rgba($black,0.3) 0%,rgba($black,0) 40%);
	
	@include media-breakpoint-up(lg){
	}

	@include media-breakpoint-up(xl){
		padding: 50px 8.33333333vw;	
	}

	.test-quote {
		font-weight: 300;
		font-size: em(18px);
		color: $black;

		@include media-breakpoint-up(lg){
			font-size: em(24px);
		}

		span {
			color:$gray;
			font-weight: 400;
			font-size: em(24px);
			
			@include media-breakpoint-up(lg){
				font-size: em(30px);
			}
		}
	}

	.attrib-name {
		margin-right: 15px;
		text-align: right;
		line-height: 1.2;

		.an-name{
			color: $black;
			font-size: em(24px);
		}

		.an-info{
			color: $gray;
			font-size: em(13px);
		}

	}

	.attrib-image {
		margin-top: 15px;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		overflow: hidden;

		@include media-breakpoint-up(lg){
			margin-top: 0; // shhh
		}

		@include media-breakpoint-up(xl){
			width: 150px;
			height: 150px;
		}

		img {
			max-width: 100%;
			height: auto;
		}

	}
}

.testimonial-spacer {
	height:0;
	margin-top:25px;

	@include media-breakpoint-up(lg){
		margin-top:45px;
	}
}

// gallery page - main
.album-item {
	margin-bottom: 15px;
	@include transition(all 0.25s ease-in-out);	
	
	@include media-breakpoint-up(lg){
		margin-bottom: 30px;
	}

	&:hover .cover {
		opacity:1;
	}
}

.cover {
	position: absolute;
	// top: 0;
	left: 0;
	bottom: 10px;
	padding:5px 15px;
	width:auto;
	// display: flex;
	// justify-content: center;
	// align-items: center;
	background-color: rgba($black,0.777);
	@include transition(all 0.25s ease-in-out);	
	opacity: 1;
	text-align:left;

	@include media-breakpoint-up(lg){
		position: absolute;
		top: 0;
		bottom: 0;
		width:100%;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba($black,0.777);
		@include transition(all 0.25s ease-in-out);	
		opacity: 0;
		text-align:center;
	}

	h3 {
		font-size: em(22px);
		font-weight: 300;
		letter-spacing: 1px;
		color: $white;
		
		@include media-breakpoint-down(lg){
			margin:0;
		}
	}

	.album-arrow {
		display: none;

		@include media-breakpoint-up(lg){
			display: inline-block;
			text-align: center;
			line-height: 20px;
			width:20px;
			// height:20px;
			border: 1px solid $white;
			border-radius: 50%;
			font-size: em(12px);
			color: $white;
		}
	}
}

// gallery page - single
#social-share {
	margin: 25px auto;
	font-size: em(14px);

	@include media-breakpoint-up(lg){
		margin-bottom: 50px;
	}

	a {
		display:inline-block;
		margin:0 10px;
		color: $black;
	}
}

.gallery-content {
	margin-top: 25px;
	
	@include media-breakpoint-up(lg){
		margin-top: 50px;
	}

	.gallery-item {
		margin-bottom: 15px;

		@include media-breakpoint-up(lg){
			margin-bottom: 30px;
		}
	}
}