/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #449fac;
  --indigo: #6610f2;
  --purple: #41313f;
  --pink: #e83e8c;
  --red: #e51e25;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #449fac;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #e51e25;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(35, 31, 32, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #449fac;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #2e6c75;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(35, 31, 32, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled, .comment-list {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid, .wp-caption > img {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure, .wp-caption {
  display: inline-block; }

.figure-img, .wp-caption > img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption, .wp-caption-text {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(35, 31, 32, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(35, 31, 32, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cbe4e8; }

.table-hover .table-primary:hover {
  background-color: #b9dbe0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b9dbe0; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8c0c2; }

.table-hover .table-danger:hover {
  background-color: #f5a9ac; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f5a9ac; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(35, 31, 32, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(21, 19, 20, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(21, 19, 20, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea, .search-form .search-field {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control, .comment-form input[type="text"],
    .comment-form input[type="email"],
    .comment-form input[type="url"],
    .comment-form textarea, .search-form .search-field {
      transition: none; } }
  .form-control::-ms-expand, .comment-form input[type="text"]::-ms-expand,
  .comment-form input[type="email"]::-ms-expand,
  .comment-form input[type="url"]::-ms-expand,
  .comment-form textarea::-ms-expand, .search-form .search-field::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, .comment-form input:focus[type="text"],
  .comment-form input:focus[type="email"],
  .comment-form input:focus[type="url"],
  .comment-form textarea:focus, .search-form .search-field:focus {
    color: #495057;
    background-color: #fff;
    border-color: #99cfd7;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 159, 172, 0.25); }
  .form-control::placeholder, .comment-form input[type="text"]::placeholder,
  .comment-form input[type="email"]::placeholder,
  .comment-form input[type="url"]::placeholder,
  .comment-form textarea::placeholder, .search-form .search-field::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .comment-form input:disabled[type="text"],
  .comment-form input:disabled[type="email"],
  .comment-form input:disabled[type="url"],
  .comment-form textarea:disabled, .search-form .search-field:disabled, .form-control[readonly], .comment-form input[readonly][type="text"],
  .comment-form input[readonly][type="email"],
  .comment-form input[readonly][type="url"],
  .comment-form textarea[readonly], .search-form .search-field[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]), .search-form select.search-field:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value, .search-form select.search-field:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control, .comment-form .input-group-sm > input.form-control-plaintext[type="text"],
  .comment-form .input-group-sm > input.form-control-plaintext[type="email"],
  .comment-form .input-group-sm > input.form-control-plaintext[type="url"],
  .comment-form .input-group-sm > textarea.form-control-plaintext, .search-form .input-group-sm > .form-control-plaintext.search-field,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn, .comment-form .input-group-sm > .input-group-prepend > input.form-control-plaintext[type="submit"], .search-form .input-group-sm > .input-group-prepend > .form-control-plaintext.search-submit,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .comment-form .input-group-sm > .input-group-append > input.form-control-plaintext[type="submit"], .search-form .input-group-sm > .input-group-append > .form-control-plaintext.search-submit, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control, .comment-form .input-group-lg > input.form-control-plaintext[type="text"],
  .comment-form .input-group-lg > input.form-control-plaintext[type="email"],
  .comment-form .input-group-lg > input.form-control-plaintext[type="url"],
  .comment-form .input-group-lg > textarea.form-control-plaintext, .search-form .input-group-lg > .form-control-plaintext.search-field,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn, .comment-form .input-group-lg > .input-group-prepend > input.form-control-plaintext[type="submit"], .search-form .input-group-lg > .input-group-prepend > .form-control-plaintext.search-submit,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn, .comment-form .input-group-lg > .input-group-append > input.form-control-plaintext[type="submit"], .search-form .input-group-lg > .input-group-append > .form-control-plaintext.search-submit {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control, .comment-form .input-group-sm > input[type="text"],
.comment-form .input-group-sm > input[type="email"],
.comment-form .input-group-sm > input[type="url"],
.comment-form .input-group-sm > textarea, .search-form .input-group-sm > .search-field,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn, .comment-form .input-group-sm > .input-group-prepend > input[type="submit"], .search-form .input-group-sm > .input-group-prepend > .search-submit,
.input-group-sm > .input-group-append > .btn, .comment-form .input-group-sm > .input-group-append > input[type="submit"], .search-form .input-group-sm > .input-group-append > .search-submit {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]), .search-form .input-group-sm > select.search-field:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]), .search-form .input-group-sm > .input-group-prepend > select.search-submit:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]), .search-form .input-group-sm > .input-group-append > select.search-submit:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control, .comment-form .input-group-lg > input[type="text"],
.comment-form .input-group-lg > input[type="email"],
.comment-form .input-group-lg > input[type="url"],
.comment-form .input-group-lg > textarea, .search-form .input-group-lg > .search-field,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn, .comment-form .input-group-lg > .input-group-prepend > input[type="submit"], .search-form .input-group-lg > .input-group-prepend > .search-submit,
.input-group-lg > .input-group-append > .btn, .comment-form .input-group-lg > .input-group-append > input[type="submit"], .search-form .input-group-lg > .input-group-append > .search-submit {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), .search-form .input-group-lg > select.search-field:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .search-form .input-group-lg > .input-group-prepend > select.search-submit:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .search-form .input-group-lg > .input-group-append > select.search-submit:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group, .comment-form p, .search-form label {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .was-validated .comment-form input:valid[type="text"], .comment-form .was-validated input:valid[type="text"],
.was-validated .comment-form input:valid[type="email"], .comment-form .was-validated input:valid[type="email"],
.was-validated .comment-form input:valid[type="url"], .comment-form .was-validated input:valid[type="url"],
.was-validated .comment-form textarea:valid, .comment-form .was-validated textarea:valid, .was-validated .search-form .search-field:valid, .search-form .was-validated .search-field:valid, .form-control.is-valid, .comment-form input.is-valid[type="text"],
.comment-form input.is-valid[type="email"],
.comment-form input.is-valid[type="url"],
.comment-form textarea.is-valid, .search-form .is-valid.search-field, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .was-validated .comment-form input:valid:focus[type="text"], .comment-form .was-validated input:valid:focus[type="text"],
  .was-validated .comment-form input:valid:focus[type="email"], .comment-form .was-validated input:valid:focus[type="email"],
  .was-validated .comment-form input:valid:focus[type="url"], .comment-form .was-validated input:valid:focus[type="url"],
  .was-validated .comment-form textarea:valid:focus, .comment-form .was-validated textarea:valid:focus, .was-validated .search-form .search-field:valid:focus, .search-form .was-validated .search-field:valid:focus, .form-control.is-valid:focus, .comment-form input.is-valid:focus[type="text"],
  .comment-form input.is-valid:focus[type="email"],
  .comment-form input.is-valid:focus[type="url"],
  .comment-form textarea.is-valid:focus, .search-form .is-valid.search-field:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback, .was-validated .comment-form input:valid[type="text"] ~ .valid-feedback, .comment-form .was-validated input:valid[type="text"] ~ .valid-feedback, .was-validated .comment-form input:valid[type="email"] ~ .valid-feedback, .comment-form .was-validated input:valid[type="email"] ~ .valid-feedback, .was-validated .comment-form input:valid[type="url"] ~ .valid-feedback, .comment-form .was-validated input:valid[type="url"] ~ .valid-feedback, .was-validated .comment-form textarea:valid ~ .valid-feedback, .comment-form .was-validated textarea:valid ~ .valid-feedback, .was-validated .search-form .search-field:valid ~ .valid-feedback, .search-form .was-validated .search-field:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .was-validated .comment-form input:valid[type="text"] ~ .valid-tooltip,
  .comment-form .was-validated input:valid[type="text"] ~ .valid-tooltip,
  .was-validated .comment-form input:valid[type="email"] ~ .valid-tooltip,
  .comment-form .was-validated input:valid[type="email"] ~ .valid-tooltip,
  .was-validated .comment-form input:valid[type="url"] ~ .valid-tooltip,
  .comment-form .was-validated input:valid[type="url"] ~ .valid-tooltip,
  .was-validated .comment-form textarea:valid ~ .valid-tooltip,
  .comment-form .was-validated textarea:valid ~ .valid-tooltip,
  .was-validated .search-form .search-field:valid ~ .valid-tooltip,
  .search-form .was-validated .search-field:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .comment-form input.is-valid[type="text"] ~ .valid-feedback, .comment-form input.is-valid[type="email"] ~ .valid-feedback, .comment-form input.is-valid[type="url"] ~ .valid-feedback, .comment-form textarea.is-valid ~ .valid-feedback, .search-form .is-valid.search-field ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  .comment-form input.is-valid[type="text"] ~ .valid-tooltip,
  .comment-form input.is-valid[type="email"] ~ .valid-tooltip,
  .comment-form input.is-valid[type="url"] ~ .valid-tooltip,
  .comment-form textarea.is-valid ~ .valid-tooltip,
  .search-form .is-valid.search-field ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e51e25; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(229, 30, 37, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .was-validated .comment-form input:invalid[type="text"], .comment-form .was-validated input:invalid[type="text"],
.was-validated .comment-form input:invalid[type="email"], .comment-form .was-validated input:invalid[type="email"],
.was-validated .comment-form input:invalid[type="url"], .comment-form .was-validated input:invalid[type="url"],
.was-validated .comment-form textarea:invalid, .comment-form .was-validated textarea:invalid, .was-validated .search-form .search-field:invalid, .search-form .was-validated .search-field:invalid, .form-control.is-invalid, .comment-form input.is-invalid[type="text"],
.comment-form input.is-invalid[type="email"],
.comment-form input.is-invalid[type="url"],
.comment-form textarea.is-invalid, .search-form .is-invalid.search-field, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e51e25; }
  .was-validated .form-control:invalid:focus, .was-validated .comment-form input:invalid:focus[type="text"], .comment-form .was-validated input:invalid:focus[type="text"],
  .was-validated .comment-form input:invalid:focus[type="email"], .comment-form .was-validated input:invalid:focus[type="email"],
  .was-validated .comment-form input:invalid:focus[type="url"], .comment-form .was-validated input:invalid:focus[type="url"],
  .was-validated .comment-form textarea:invalid:focus, .comment-form .was-validated textarea:invalid:focus, .was-validated .search-form .search-field:invalid:focus, .search-form .was-validated .search-field:invalid:focus, .form-control.is-invalid:focus, .comment-form input.is-invalid:focus[type="text"],
  .comment-form input.is-invalid:focus[type="email"],
  .comment-form input.is-invalid:focus[type="url"],
  .comment-form textarea.is-invalid:focus, .search-form .is-invalid.search-field:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #e51e25;
    box-shadow: 0 0 0 0.2rem rgba(229, 30, 37, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .comment-form input:invalid[type="text"] ~ .invalid-feedback, .comment-form .was-validated input:invalid[type="text"] ~ .invalid-feedback, .was-validated .comment-form input:invalid[type="email"] ~ .invalid-feedback, .comment-form .was-validated input:invalid[type="email"] ~ .invalid-feedback, .was-validated .comment-form input:invalid[type="url"] ~ .invalid-feedback, .comment-form .was-validated input:invalid[type="url"] ~ .invalid-feedback, .was-validated .comment-form textarea:invalid ~ .invalid-feedback, .comment-form .was-validated textarea:invalid ~ .invalid-feedback, .was-validated .search-form .search-field:invalid ~ .invalid-feedback, .search-form .was-validated .search-field:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .was-validated .comment-form input:invalid[type="text"] ~ .invalid-tooltip,
  .comment-form .was-validated input:invalid[type="text"] ~ .invalid-tooltip,
  .was-validated .comment-form input:invalid[type="email"] ~ .invalid-tooltip,
  .comment-form .was-validated input:invalid[type="email"] ~ .invalid-tooltip,
  .was-validated .comment-form input:invalid[type="url"] ~ .invalid-tooltip,
  .comment-form .was-validated input:invalid[type="url"] ~ .invalid-tooltip,
  .was-validated .comment-form textarea:invalid ~ .invalid-tooltip,
  .comment-form .was-validated textarea:invalid ~ .invalid-tooltip,
  .was-validated .search-form .search-field:invalid ~ .invalid-tooltip,
  .search-form .was-validated .search-field:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .comment-form input.is-invalid[type="text"] ~ .invalid-feedback, .comment-form input.is-invalid[type="email"] ~ .invalid-feedback, .comment-form input.is-invalid[type="url"] ~ .invalid-feedback, .comment-form textarea.is-invalid ~ .invalid-feedback, .search-form .is-invalid.search-field ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .comment-form input.is-invalid[type="text"] ~ .invalid-tooltip,
  .comment-form input.is-invalid[type="email"] ~ .invalid-tooltip,
  .comment-form input.is-invalid[type="url"] ~ .invalid-tooltip,
  .comment-form textarea.is-invalid ~ .invalid-tooltip,
  .search-form .is-invalid.search-field ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e51e25; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e51e25; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #f29094; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ea4c51; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(229, 30, 37, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e51e25; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(229, 30, 37, 0.25); }

.form-inline, .search-form {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check, .search-form .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label, .search-form label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group, .search-form .form-group, .form-inline .comment-form p, .comment-form .form-inline p, .search-form .comment-form p, .comment-form .search-form p, .form-inline .search-form label, .search-form .form-inline label, .search-form label {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .search-form .form-control, .form-inline .comment-form input[type="text"], .comment-form .form-inline input[type="text"], .search-form .comment-form input[type="text"], .comment-form .search-form input[type="text"],
    .form-inline .comment-form input[type="email"], .comment-form .form-inline input[type="email"],
    .search-form .comment-form input[type="email"], .comment-form .search-form input[type="email"],
    .form-inline .comment-form input[type="url"], .comment-form .form-inline input[type="url"],
    .search-form .comment-form input[type="url"], .comment-form .search-form input[type="url"],
    .form-inline .comment-form textarea, .comment-form .form-inline textarea,
    .search-form .comment-form textarea, .comment-form .search-form textarea, .search-form .search-field {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext, .search-form .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group, .search-form .input-group,
    .form-inline .custom-select,
    .search-form .custom-select {
      width: auto; }
    .form-inline .form-check, .search-form .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input, .search-form .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control, .search-form .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label, .search-form .custom-control-label {
      margin-bottom: 0; } }

.btn, .comment-form input[type="submit"], .search-form .search-submit {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn, .comment-form input[type="submit"], .search-form .search-submit {
      transition: none; } }
  .btn:hover, .comment-form input:hover[type="submit"], .search-form .search-submit:hover, .btn:focus, .comment-form input:focus[type="submit"], .search-form .search-submit:focus {
    text-decoration: none; }
  .btn:focus, .comment-form input:focus[type="submit"], .search-form .search-submit:focus, .btn.focus, .comment-form input.focus[type="submit"], .search-form .focus.search-submit {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 159, 172, 0.25); }
  .btn.disabled, .comment-form input.disabled[type="submit"], .search-form .disabled.search-submit, .btn:disabled, .comment-form input:disabled[type="submit"], .search-form .search-submit:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled), .comment-form input:not(:disabled):not(.disabled)[type="submit"], .search-form .search-submit:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .comment-form input:not(:disabled):not(.disabled):active[type="submit"], .search-form .search-submit:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .comment-form input:not(:disabled):not(.disabled).active[type="submit"], .search-form .search-submit:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled, .search-form a.disabled.search-submit,
fieldset:disabled a.btn,
fieldset:disabled .search-form a.search-submit,
.search-form fieldset:disabled a.search-submit {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #449fac;
  border-color: #449fac; }
  .btn-primary:hover {
    color: #fff;
    background-color: #398691;
    border-color: #367d87; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(68, 159, 172, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #449fac;
    border-color: #449fac; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #367d87;
    border-color: #32757e; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(68, 159, 172, 0.5); }

.btn-secondary, .comment-form input[type="submit"], .search-form .search-submit {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover, .comment-form input:hover[type="submit"], .search-form .search-submit:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .comment-form input:focus[type="submit"], .search-form .search-submit:focus, .btn-secondary.focus, .comment-form input.focus[type="submit"], .search-form .focus.search-submit {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .comment-form input.disabled[type="submit"], .search-form .disabled.search-submit, .btn-secondary:disabled, .comment-form input:disabled[type="submit"], .search-form .search-submit:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .comment-form input:not(:disabled):not(.disabled):active[type="submit"], .search-form .search-submit:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .comment-form input:not(:disabled):not(.disabled).active[type="submit"], .search-form .search-submit:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle,
  .comment-form .show > input.dropdown-toggle[type="submit"],
  .search-form .show > .dropdown-toggle.search-submit {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .comment-form input:not(:disabled):not(.disabled):active:focus[type="submit"], .search-form .search-submit:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .comment-form input:not(:disabled):not(.disabled).active:focus[type="submit"], .search-form .search-submit:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus,
    .comment-form .show > input.dropdown-toggle:focus[type="submit"],
    .search-form .show > .dropdown-toggle.search-submit:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #e51e25;
  border-color: #e51e25; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c6171d;
    border-color: #ba161b; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(229, 30, 37, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #e51e25;
    border-color: #e51e25; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ba161b;
    border-color: #af141a; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(229, 30, 37, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #449fac;
  background-color: transparent;
  background-image: none;
  border-color: #449fac; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #449fac;
    border-color: #449fac; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(68, 159, 172, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #449fac;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #449fac;
    border-color: #449fac; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(68, 159, 172, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #e51e25;
  background-color: transparent;
  background-image: none;
  border-color: #e51e25; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #e51e25;
    border-color: #e51e25; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(229, 30, 37, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #e51e25;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e51e25;
    border-color: #e51e25; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(229, 30, 37, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #449fac;
  background-color: transparent; }
  .btn-link:hover {
    color: #2e6c75;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .comment-form .btn-group-lg > input[type="submit"], .search-form .btn-group-lg > .search-submit {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .comment-form .btn-group-sm > input[type="submit"], .search-form .btn-group-sm > .search-submit {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(35, 31, 32, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #449fac; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .comment-form .btn-group > input[type="submit"], .search-form .btn-group > .search-submit,
  .btn-group-vertical > .btn,
  .comment-form .btn-group-vertical > input[type="submit"],
  .search-form .btn-group-vertical > .search-submit {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover, .comment-form .btn-group > input:hover[type="submit"], .search-form .btn-group > .search-submit:hover,
    .btn-group-vertical > .btn:hover,
    .comment-form .btn-group-vertical > input:hover[type="submit"],
    .search-form .btn-group-vertical > .search-submit:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .comment-form .btn-group > input:focus[type="submit"], .search-form .btn-group > .search-submit:focus, .btn-group > .btn:active, .comment-form .btn-group > input:active[type="submit"], .search-form .btn-group > .search-submit:active, .btn-group > .btn.active, .comment-form .btn-group > input.active[type="submit"], .search-form .btn-group > .active.search-submit,
    .btn-group-vertical > .btn:focus,
    .comment-form .btn-group-vertical > input:focus[type="submit"],
    .search-form .btn-group-vertical > .search-submit:focus,
    .btn-group-vertical > .btn:active,
    .comment-form .btn-group-vertical > input:active[type="submit"],
    .search-form .btn-group-vertical > .search-submit:active,
    .btn-group-vertical > .btn.active,
    .comment-form .btn-group-vertical > input.active[type="submit"],
    .search-form .btn-group-vertical > .active.search-submit {
      z-index: 1; }
  .btn-group .btn + .btn, .btn-group .comment-form input[type="submit"] + .btn, .comment-form .btn-group input[type="submit"] + .btn, .btn-group .search-form .search-submit + .btn, .search-form .btn-group .search-submit + .btn, .btn-group .comment-form .btn + input[type="submit"], .comment-form .btn-group .btn + input[type="submit"], .btn-group .comment-form input[type="submit"] + input[type="submit"], .comment-form .btn-group input[type="submit"] + input[type="submit"], .btn-group .search-form .comment-form .search-submit + input[type="submit"], .comment-form .btn-group .search-form .search-submit + input[type="submit"], .search-form .btn-group .comment-form .search-submit + input[type="submit"], .comment-form .search-form .btn-group .search-submit + input[type="submit"], .btn-group .search-form .btn + .search-submit, .search-form .btn-group .btn + .search-submit, .btn-group .comment-form .search-form input[type="submit"] + .search-submit, .search-form .btn-group .comment-form input[type="submit"] + .search-submit, .comment-form .btn-group .search-form input[type="submit"] + .search-submit, .search-form .comment-form .btn-group input[type="submit"] + .search-submit, .btn-group .search-form .search-submit + .search-submit, .search-form .btn-group .search-submit + .search-submit,
  .btn-group .btn + .btn-group,
  .btn-group .comment-form input[type="submit"] + .btn-group,
  .comment-form .btn-group input[type="submit"] + .btn-group,
  .btn-group .search-form .search-submit + .btn-group,
  .search-form .btn-group .search-submit + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .comment-form .btn-group + input[type="submit"],
  .comment-form .btn-group .btn-group + input[type="submit"],
  .btn-group .search-form .btn-group + .search-submit,
  .search-form .btn-group .btn-group + .search-submit,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .comment-form input[type="submit"] + .btn,
  .comment-form .btn-group-vertical input[type="submit"] + .btn,
  .btn-group-vertical .search-form .search-submit + .btn,
  .search-form .btn-group-vertical .search-submit + .btn,
  .btn-group-vertical .comment-form .btn + input[type="submit"],
  .comment-form .btn-group-vertical .btn + input[type="submit"],
  .btn-group-vertical .comment-form input[type="submit"] + input[type="submit"],
  .comment-form .btn-group-vertical input[type="submit"] + input[type="submit"],
  .btn-group-vertical .search-form .comment-form .search-submit + input[type="submit"],
  .comment-form .btn-group-vertical .search-form .search-submit + input[type="submit"],
  .search-form .btn-group-vertical .comment-form .search-submit + input[type="submit"],
  .comment-form .search-form .btn-group-vertical .search-submit + input[type="submit"],
  .btn-group-vertical .search-form .btn + .search-submit,
  .search-form .btn-group-vertical .btn + .search-submit,
  .btn-group-vertical .comment-form .search-form input[type="submit"] + .search-submit,
  .search-form .btn-group-vertical .comment-form input[type="submit"] + .search-submit,
  .comment-form .btn-group-vertical .search-form input[type="submit"] + .search-submit,
  .search-form .comment-form .btn-group-vertical input[type="submit"] + .search-submit,
  .btn-group-vertical .search-form .search-submit + .search-submit,
  .search-form .btn-group-vertical .search-submit + .search-submit,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .comment-form input[type="submit"] + .btn-group,
  .comment-form .btn-group-vertical input[type="submit"] + .btn-group,
  .btn-group-vertical .search-form .search-submit + .btn-group,
  .search-form .btn-group-vertical .search-submit + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .comment-form .btn-group + input[type="submit"],
  .comment-form .btn-group-vertical .btn-group + input[type="submit"],
  .btn-group-vertical .search-form .btn-group + .search-submit,
  .search-form .btn-group-vertical .btn-group + .search-submit,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child, .comment-form .btn-group > input:first-child[type="submit"], .search-form .btn-group > .search-submit:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .comment-form .btn-group > input:not(:last-child):not(.dropdown-toggle)[type="submit"], .search-form .btn-group > .search-submit:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.comment-form .btn-group > .btn-group:not(:last-child) > input[type="submit"],
.search-form .btn-group > .btn-group:not(:last-child) > .search-submit {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .comment-form .btn-group > input:not(:first-child)[type="submit"], .search-form .btn-group > .search-submit:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.comment-form .btn-group > .btn-group:not(:first-child) > input[type="submit"],
.search-form .btn-group > .btn-group:not(:first-child) > .search-submit {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .comment-form .btn-group-sm > input[type="submit"] + .dropdown-toggle-split, .search-form .btn-group-sm > .search-submit + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .comment-form .btn-group-lg > input[type="submit"] + .dropdown-toggle-split, .search-form .btn-group-lg > .search-submit + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn, .btn-group-vertical .comment-form input[type="submit"], .comment-form .btn-group-vertical input[type="submit"], .btn-group-vertical .search-form .search-submit, .search-form .btn-group-vertical .search-submit,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn, .comment-form .btn-group-vertical > input[type="submit"] + .btn, .search-form .btn-group-vertical > .search-submit + .btn, .comment-form .btn-group-vertical > .btn + input[type="submit"], .comment-form .btn-group-vertical > input[type="submit"] + input[type="submit"], .search-form .comment-form .btn-group-vertical > .search-submit + input[type="submit"], .comment-form .search-form .btn-group-vertical > .search-submit + input[type="submit"], .search-form .btn-group-vertical > .btn + .search-submit, .comment-form .search-form .btn-group-vertical > input[type="submit"] + .search-submit, .search-form .comment-form .btn-group-vertical > input[type="submit"] + .search-submit, .search-form .btn-group-vertical > .search-submit + .search-submit,
  .btn-group-vertical > .btn + .btn-group,
  .comment-form .btn-group-vertical > input[type="submit"] + .btn-group,
  .search-form .btn-group-vertical > .search-submit + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .comment-form .btn-group-vertical > .btn-group + input[type="submit"],
  .search-form .btn-group-vertical > .btn-group + .search-submit,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .comment-form .btn-group-vertical > input:not(:last-child):not(.dropdown-toggle)[type="submit"], .search-form .btn-group-vertical > .search-submit:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .comment-form .btn-group-vertical > .btn-group:not(:last-child) > input[type="submit"],
  .search-form .btn-group-vertical > .btn-group:not(:last-child) > .search-submit {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .comment-form .btn-group-vertical > input:not(:first-child)[type="submit"], .search-form .btn-group-vertical > .search-submit:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .comment-form .btn-group-vertical > .btn-group:not(:first-child) > input[type="submit"],
  .search-form .btn-group-vertical > .btn-group:not(:first-child) > .search-submit {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .comment-form .btn-group-toggle > input[type="submit"], .search-form .btn-group-toggle > .search-submit,
.btn-group-toggle > .btn-group > .btn,
.comment-form .btn-group-toggle > .btn-group > input[type="submit"],
.search-form .btn-group-toggle > .btn-group > .search-submit {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .comment-form .btn-group-toggle > input[type="submit"] input[type="radio"], .search-form .btn-group-toggle > .search-submit input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .comment-form .btn-group-toggle > input[type="submit"] input[type="checkbox"],
  .search-form .btn-group-toggle > .search-submit input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .comment-form .btn-group-toggle > .btn-group > input[type="submit"] input[type="radio"],
  .search-form .btn-group-toggle > .btn-group > .search-submit input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .comment-form .btn-group-toggle > .btn-group > input[type="submit"] input[type="checkbox"],
  .search-form .btn-group-toggle > .btn-group > .search-submit input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .comment-form .input-group > input[type="text"],
  .comment-form .input-group > input[type="email"],
  .comment-form .input-group > input[type="url"],
  .comment-form .input-group > textarea, .search-form .input-group > .search-field,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus, .comment-form .input-group > input:focus[type="text"],
    .comment-form .input-group > input:focus[type="email"],
    .comment-form .input-group > input:focus[type="url"],
    .comment-form .input-group > textarea:focus, .search-form .input-group > .search-field:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control, .comment-form .input-group > input[type="text"] + .form-control, .comment-form .input-group > input[type="email"] + .form-control, .comment-form .input-group > input[type="url"] + .form-control, .comment-form .input-group > textarea + .form-control, .search-form .input-group > .search-field + .form-control, .comment-form .input-group > .form-control + input[type="text"], .comment-form .input-group > input[type="text"] + input[type="text"], .comment-form .input-group > input[type="email"] + input[type="text"], .comment-form .input-group > input[type="url"] + input[type="text"], .comment-form .input-group > textarea + input[type="text"], .search-form .comment-form .input-group > .search-field + input[type="text"], .comment-form .search-form .input-group > .search-field + input[type="text"],
    .comment-form .input-group > .form-control + input[type="email"],
    .comment-form .input-group > input[type="text"] + input[type="email"],
    .comment-form .input-group > input[type="email"] + input[type="email"],
    .comment-form .input-group > input[type="url"] + input[type="email"],
    .comment-form .input-group > textarea + input[type="email"],
    .search-form .comment-form .input-group > .search-field + input[type="email"], .comment-form .search-form .input-group > .search-field + input[type="email"],
    .comment-form .input-group > .form-control + input[type="url"],
    .comment-form .input-group > input[type="text"] + input[type="url"],
    .comment-form .input-group > input[type="email"] + input[type="url"],
    .comment-form .input-group > input[type="url"] + input[type="url"],
    .comment-form .input-group > textarea + input[type="url"],
    .search-form .comment-form .input-group > .search-field + input[type="url"], .comment-form .search-form .input-group > .search-field + input[type="url"],
    .comment-form .input-group > .form-control + textarea,
    .comment-form .input-group > input[type="text"] + textarea,
    .comment-form .input-group > input[type="email"] + textarea,
    .comment-form .input-group > input[type="url"] + textarea,
    .comment-form .input-group > textarea + textarea,
    .search-form .comment-form .input-group > .search-field + textarea, .comment-form .search-form .input-group > .search-field + textarea, .search-form .input-group > .form-control + .search-field, .comment-form .search-form .input-group > input[type="text"] + .search-field, .search-form .comment-form .input-group > input[type="text"] + .search-field, .comment-form .search-form .input-group > input[type="email"] + .search-field, .search-form .comment-form .input-group > input[type="email"] + .search-field, .comment-form .search-form .input-group > input[type="url"] + .search-field, .search-form .comment-form .input-group > input[type="url"] + .search-field, .comment-form .search-form .input-group > textarea + .search-field, .search-form .comment-form .input-group > textarea + .search-field, .search-form .input-group > .search-field + .search-field,
    .input-group > .form-control + .custom-select,
    .comment-form .input-group > input[type="text"] + .custom-select,
    .comment-form .input-group > input[type="email"] + .custom-select,
    .comment-form .input-group > input[type="url"] + .custom-select,
    .comment-form .input-group > textarea + .custom-select,
    .search-form .input-group > .search-field + .custom-select,
    .input-group > .form-control + .custom-file,
    .comment-form .input-group > input[type="text"] + .custom-file,
    .comment-form .input-group > input[type="email"] + .custom-file,
    .comment-form .input-group > input[type="url"] + .custom-file,
    .comment-form .input-group > textarea + .custom-file,
    .search-form .input-group > .search-field + .custom-file,
    .input-group > .custom-select + .form-control,
    .comment-form .input-group > .custom-select + input[type="text"],
    .comment-form .input-group > .custom-select + input[type="email"],
    .comment-form .input-group > .custom-select + input[type="url"],
    .comment-form .input-group > .custom-select + textarea,
    .search-form .input-group > .custom-select + .search-field,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .comment-form .input-group > .custom-file + input[type="text"],
    .comment-form .input-group > .custom-file + input[type="email"],
    .comment-form .input-group > .custom-file + input[type="url"],
    .comment-form .input-group > .custom-file + textarea,
    .search-form .input-group > .custom-file + .search-field,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child), .comment-form .input-group > input:not(:last-child)[type="text"],
  .comment-form .input-group > input:not(:last-child)[type="email"],
  .comment-form .input-group > input:not(:last-child)[type="url"],
  .comment-form .input-group > textarea:not(:last-child), .search-form .input-group > .search-field:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .comment-form .input-group > input:not(:first-child)[type="text"],
  .comment-form .input-group > input:not(:first-child)[type="email"],
  .comment-form .input-group > input:not(:first-child)[type="url"],
  .comment-form .input-group > textarea:not(:first-child), .search-form .input-group > .search-field:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .comment-form input[type="submit"], .comment-form .input-group-prepend input[type="submit"], .input-group-prepend .search-form .search-submit, .search-form .input-group-prepend .search-submit,
  .input-group-append .btn,
  .input-group-append .comment-form input[type="submit"],
  .comment-form .input-group-append input[type="submit"],
  .input-group-append .search-form .search-submit,
  .search-form .input-group-append .search-submit {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn, .input-group-prepend .comment-form input[type="submit"] + .btn, .comment-form .input-group-prepend input[type="submit"] + .btn, .input-group-prepend .search-form .search-submit + .btn, .search-form .input-group-prepend .search-submit + .btn, .input-group-prepend .comment-form .btn + input[type="submit"], .comment-form .input-group-prepend .btn + input[type="submit"], .input-group-prepend .comment-form input[type="submit"] + input[type="submit"], .comment-form .input-group-prepend input[type="submit"] + input[type="submit"], .input-group-prepend .search-form .comment-form .search-submit + input[type="submit"], .comment-form .input-group-prepend .search-form .search-submit + input[type="submit"], .search-form .input-group-prepend .comment-form .search-submit + input[type="submit"], .comment-form .search-form .input-group-prepend .search-submit + input[type="submit"], .input-group-prepend .search-form .btn + .search-submit, .search-form .input-group-prepend .btn + .search-submit, .input-group-prepend .comment-form .search-form input[type="submit"] + .search-submit, .search-form .input-group-prepend .comment-form input[type="submit"] + .search-submit, .comment-form .input-group-prepend .search-form input[type="submit"] + .search-submit, .search-form .comment-form .input-group-prepend input[type="submit"] + .search-submit, .input-group-prepend .search-form .search-submit + .search-submit, .search-form .input-group-prepend .search-submit + .search-submit,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .comment-form input[type="submit"] + .input-group-text,
  .comment-form .input-group-prepend input[type="submit"] + .input-group-text,
  .input-group-prepend .search-form .search-submit + .input-group-text,
  .search-form .input-group-prepend .search-submit + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .comment-form .input-group-text + input[type="submit"],
  .comment-form .input-group-prepend .input-group-text + input[type="submit"],
  .input-group-prepend .search-form .input-group-text + .search-submit,
  .search-form .input-group-prepend .input-group-text + .search-submit,
  .input-group-append .btn + .btn,
  .input-group-append .comment-form input[type="submit"] + .btn,
  .comment-form .input-group-append input[type="submit"] + .btn,
  .input-group-append .search-form .search-submit + .btn,
  .search-form .input-group-append .search-submit + .btn,
  .input-group-append .comment-form .btn + input[type="submit"],
  .comment-form .input-group-append .btn + input[type="submit"],
  .input-group-append .comment-form input[type="submit"] + input[type="submit"],
  .comment-form .input-group-append input[type="submit"] + input[type="submit"],
  .input-group-append .search-form .comment-form .search-submit + input[type="submit"],
  .comment-form .input-group-append .search-form .search-submit + input[type="submit"],
  .search-form .input-group-append .comment-form .search-submit + input[type="submit"],
  .comment-form .search-form .input-group-append .search-submit + input[type="submit"],
  .input-group-append .search-form .btn + .search-submit,
  .search-form .input-group-append .btn + .search-submit,
  .input-group-append .comment-form .search-form input[type="submit"] + .search-submit,
  .search-form .input-group-append .comment-form input[type="submit"] + .search-submit,
  .comment-form .input-group-append .search-form input[type="submit"] + .search-submit,
  .search-form .comment-form .input-group-append input[type="submit"] + .search-submit,
  .input-group-append .search-form .search-submit + .search-submit,
  .search-form .input-group-append .search-submit + .search-submit,
  .input-group-append .btn + .input-group-text,
  .input-group-append .comment-form input[type="submit"] + .input-group-text,
  .comment-form .input-group-append input[type="submit"] + .input-group-text,
  .input-group-append .search-form .search-submit + .input-group-text,
  .search-form .input-group-append .search-submit + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .comment-form .input-group-text + input[type="submit"],
  .comment-form .input-group-append .input-group-text + input[type="submit"],
  .input-group-append .search-form .input-group-text + .search-submit,
  .search-form .input-group-append .input-group-text + .search-submit {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn, .comment-form .input-group > .input-group-prepend > input[type="submit"], .search-form .input-group > .input-group-prepend > .search-submit,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.comment-form .input-group > .input-group-append:not(:last-child) > input[type="submit"],
.search-form .input-group > .input-group-append:not(:last-child) > .search-submit,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.comment-form .input-group > .input-group-append:last-child > input:not(:last-child):not(.dropdown-toggle)[type="submit"],
.search-form .input-group > .input-group-append:last-child > .search-submit:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .comment-form .input-group > .input-group-append > input[type="submit"], .search-form .input-group > .input-group-append > .search-submit,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.comment-form .input-group > .input-group-prepend:not(:first-child) > input[type="submit"],
.search-form .input-group > .input-group-prepend:not(:first-child) > .search-submit,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.comment-form .input-group > .input-group-prepend:first-child > input:not(:first-child)[type="submit"],
.search-form .input-group > .input-group-prepend:first-child > .search-submit:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #449fac; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(68, 159, 172, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #bde0e5; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #449fac; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #449fac; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(68, 159, 172, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(68, 159, 172, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #449fac; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(68, 159, 172, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #99cfd7;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(35, 31, 32, 0.075), 0 0 5px rgba(153, 207, 215, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #99cfd7;
    box-shadow: 0 0 0 0.2rem rgba(68, 159, 172, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #99cfd7; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #449fac;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-webkit-slider-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(68, 159, 172, 0.25); }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #bde0e5; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #449fac;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-moz-range-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(68, 159, 172, 0.25); }
    .custom-range::-moz-range-thumb:active {
      background-color: #bde0e5; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #449fac;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-ms-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(68, 159, 172, 0.25); }
    .custom-range::-ms-thumb:active {
      background-color: #bde0e5; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #449fac; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(35, 31, 32, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(35, 31, 32, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(35, 31, 32, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(35, 31, 32, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(35, 31, 32, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(35, 31, 32, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(35, 31, 32, 0.5);
  border-color: rgba(35, 31, 32, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(35, 31, 32, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(35, 31, 32, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(35, 31, 32, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(35, 31, 32, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(35, 31, 32, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(35, 31, 32, 0.03);
  border-bottom: 1px solid rgba(35, 31, 32, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(35, 31, 32, 0.03);
  border-top: 1px solid rgba(35, 31, 32, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #449fac;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #2e6c75;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 159, 172, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #449fac;
  border-color: #449fac; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge, .comment-form input[type="submit"] .badge, .search-form .search-submit .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #449fac; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #367d87; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #e51e25; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ba161b; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #346269;
  background-color: #daecee;
  border-color: #cbe4e8; }
  .alert-primary hr {
    border-top-color: #b9dbe0; }
  .alert-primary .alert-link {
    color: #234247; }

.alert-secondary {
  color: #494c50;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #313335; }

.alert-success {
  color: #266633;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #184120; }

.alert-info {
  color: #1d636f;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #123f47; }

.alert-warning {
  color: #957313;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #68500d; }

.alert-danger {
  color: #881e23;
  background-color: #fad2d3;
  border-color: #f8c0c2; }
  .alert-danger hr {
    border-top-color: #f5a9ac; }
  .alert-danger .alert-link {
    color: #5e1518; }

.alert-light {
  color: #929091;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #797678; }

.alert-dark {
  color: #2c2d31;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #141416; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #449fac;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(35, 31, 32, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #449fac;
    border-color: #449fac; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #346269;
  background-color: #cbe4e8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #346269;
    background-color: #b9dbe0; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #346269;
    border-color: #346269; }

.list-group-item-secondary {
  color: #494c50;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #494c50;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #494c50;
    border-color: #494c50; }

.list-group-item-success {
  color: #266633;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #266633;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #266633;
    border-color: #266633; }

.list-group-item-info {
  color: #1d636f;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #1d636f;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #1d636f;
    border-color: #1d636f; }

.list-group-item-warning {
  color: #957313;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #957313;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #957313;
    border-color: #957313; }

.list-group-item-danger {
  color: #881e23;
  background-color: #f8c0c2; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #881e23;
    background-color: #f5a9ac; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #881e23;
    border-color: #881e23; }

.list-group-item-light {
  color: #929091;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #929091;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #929091;
    border-color: #929091; }

.list-group-item-dark {
  color: #2c2d31;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #2c2d31;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #2c2d31;
    border-color: #2c2d31; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #231f20;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #231f20;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(35, 31, 32, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #231f20; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #231f20; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #231f20; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #231f20; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #231f20; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #231f20;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(35, 31, 32, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(35, 31, 32, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(35, 31, 32, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(35, 31, 32, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(35, 31, 32, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #449fac !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #367d87 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #e51e25 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ba161b !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #449fac !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #e51e25 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only, .screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .screen-reader-text:active, .sr-only-focusable:focus, .screen-reader-text:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(35, 31, 32, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(35, 31, 32, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(35, 31, 32, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #449fac !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #367d87 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #e51e25 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ba161b !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(35, 31, 32, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #231f20; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.col-xs-1 {
  max-width: 8.3333333333%; }

.col-xs-2 {
  max-width: 16.6666666667%; }

.col-xs-3 {
  max-width: 25%; }

.col-xs-4 {
  max-width: 33.3333333333%; }

.col-xs-5 {
  max-width: 41.6666666667%; }

.col-xs-6 {
  max-width: 50%; }

.col-xs-7 {
  max-width: 58.3333333333%; }

.col-xs-8 {
  max-width: 66.6666666667%; }

.col-xs-9 {
  max-width: 75%; }

.col-xs-10 {
  max-width: 83.3333333333%; }

.col-xs-11 {
  max-width: 91.6666666667%; }

.col-xs-12 {
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm-1 {
    max-width: 8.3333333333%; }
  .col-sm-2 {
    max-width: 16.6666666667%; }
  .col-sm-3 {
    max-width: 25%; }
  .col-sm-4 {
    max-width: 33.3333333333%; }
  .col-sm-5 {
    max-width: 41.6666666667%; }
  .col-sm-6 {
    max-width: 50%; }
  .col-sm-7 {
    max-width: 58.3333333333%; }
  .col-sm-8 {
    max-width: 66.6666666667%; }
  .col-sm-9 {
    max-width: 75%; }
  .col-sm-10 {
    max-width: 83.3333333333%; }
  .col-sm-11 {
    max-width: 91.6666666667%; }
  .col-sm-12 {
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md-1 {
    max-width: 8.3333333333%; }
  .col-md-2 {
    max-width: 16.6666666667%; }
  .col-md-3 {
    max-width: 25%; }
  .col-md-4 {
    max-width: 33.3333333333%; }
  .col-md-5 {
    max-width: 41.6666666667%; }
  .col-md-6 {
    max-width: 50%; }
  .col-md-7 {
    max-width: 58.3333333333%; }
  .col-md-8 {
    max-width: 66.6666666667%; }
  .col-md-9 {
    max-width: 75%; }
  .col-md-10 {
    max-width: 83.3333333333%; }
  .col-md-11 {
    max-width: 91.6666666667%; }
  .col-md-12 {
    max-width: 100%; } }

@media (min-width: 992px) {
  .col-lg-1 {
    max-width: 8.3333333333%; }
  .col-lg-2 {
    max-width: 16.6666666667%; }
  .col-lg-3 {
    max-width: 25%; }
  .col-lg-4 {
    max-width: 33.3333333333%; }
  .col-lg-5 {
    max-width: 41.6666666667%; }
  .col-lg-6 {
    max-width: 50%; }
  .col-lg-7 {
    max-width: 58.3333333333%; }
  .col-lg-8 {
    max-width: 66.6666666667%; }
  .col-lg-9 {
    max-width: 75%; }
  .col-lg-10 {
    max-width: 83.3333333333%; }
  .col-lg-11 {
    max-width: 91.6666666667%; }
  .col-lg-12 {
    max-width: 100%; } }

@media (min-width: 1200px) {
  .col-xl-1 {
    max-width: 8.3333333333%; }
  .col-xl-2 {
    max-width: 16.6666666667%; }
  .col-xl-3 {
    max-width: 25%; }
  .col-xl-4 {
    max-width: 33.3333333333%; }
  .col-xl-5 {
    max-width: 41.6666666667%; }
  .col-xl-6 {
    max-width: 50%; }
  .col-xl-7 {
    max-width: 58.3333333333%; }
  .col-xl-8 {
    max-width: 66.6666666667%; }
  .col-xl-9 {
    max-width: 75%; }
  .col-xl-10 {
    max-width: 83.3333333333%; }
  .col-xl-11 {
    max-width: 91.6666666667%; }
  .col-xl-12 {
    max-width: 100%; } }

.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: '';
    display: table;
    clear: both; }

/*============================================================================
  Prefixer mixin for generating vendor prefixes:
    - Based on https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_prefixer.scss
    - Usage:

      // Input:
      .element {
        @include prefixer(transform, scale(1), ms webkit spec);
      }

      // Output:
      .element {
        -ms-transform:scale(1);
        -webkit-transform:scale(1);
        transform:scale(1);
      }
==============================================================================*/
.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px; }

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%; }

.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

@media (max-width: 1199.98px) {
  .mobile-hide {
    display: none; } }

@media (min-width: 992px) {
  .desktop-hide {
    display: none; } }

.top-center {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center; }

.bottom-center {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center; }

.middle-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%); }

.rwd-br {
  display: none; }
  @media (min-width: 992px) {
    .rwd-br {
      display: inline-block; } }

@font-face {
  font-family: 'webicon';
  src: url("../fonts/webicon.eot?ynccn3");
  src: url("../fonts/webicon.eot?ynccn3#iefix") format("embedded-opentype"), url("../fonts/webicon.ttf?ynccn3") format("truetype"), url("../fonts/webicon.woff?ynccn3") format("woff"), url("../fonts/webicon.svg?ynccn3#webicon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="webicon-"], [class*=" webicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'webicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.webicon-play:before {
  content: "\e912"; }

.webicon-phone:before {
  content: "\e942"; }

.webicon-phone-hang-up:before {
  content: "\e943"; }

.webicon-display:before {
  content: "\e956"; }

.webicon-mobile2:before {
  content: "\e959"; }

.webicon-checkbox-checked:before {
  content: "\ea52"; }

.webicon-checkbox-unchecked:before {
  content: "\ea53"; }

.webicon-radio-checked:before {
  content: "\ea54"; }

.webicon-radio-checked2:before {
  content: "\ea55"; }

.webicon-radio-unchecked:before {
  content: "\ea56"; }

.webicon-mail:before {
  content: "\ea83"; }

.webicon-mail2:before {
  content: "\ea84"; }

.webicon-mail3:before {
  content: "\ea85"; }

.webicon-mail4:before {
  content: "\ea86"; }

.webicon-mail_outline:before {
  content: "\e0e1"; }

.webicon-insert_invitation:before {
  content: "\e24f"; }

.webicon-keyboard_arrow_down:before {
  content: "\e313"; }

.webicon-keyboard_arrow_left:before {
  content: "\e314"; }

.webicon-keyboard_arrow_right:before {
  content: "\e315"; }

.webicon-keyboard_arrow_up:before {
  content: "\e316"; }

.webicon-close:before {
  content: "\e5cd"; }

.webicon-pdf:before {
  content: "\e902"; }

.webicon-talk:before {
  content: "\e903"; }

.webicon-houzz:before {
  content: "\e915"; }

.webicon-zoom-in:before {
  content: "\e987"; }

.webicon-enlarge:before {
  content: "\e989"; }

.webicon-plus:before {
  content: "\ea0a"; }

.webicon-minus:before {
  content: "\ea0b"; }

.webicon-info:before {
  content: "\ea0c"; }

.webicon-cancel-circle:before {
  content: "\ea0d"; }

.webicon-blocked:before {
  content: "\ea0e"; }

.webicon-cross:before {
  content: "\ea0f"; }

.webicon-checkmark:before {
  content: "\ea10"; }

.webicon-checkmark-hollow:before {
  content: "\ea11"; }

.webicon-arrow-up2:before {
  content: "\ea3a"; }

.webicon-arrow-right2:before {
  content: "\ea3c"; }

.webicon-arrow-down2:before {
  content: "\ea3e"; }

.webicon-arrow-left2:before {
  content: "\ea40"; }

.webicon-circle-up:before {
  content: "\ea41"; }

.webicon-circle-right:before {
  content: "\ea42"; }

.webicon-circle-down:before {
  content: "\ea43"; }

.webicon-circle-left:before {
  content: "\ea44"; }

.webicon-terminal:before {
  content: "\ea81"; }

.webicon-share2:before {
  content: "\ea82"; }

.webicon-google:before {
  content: "\ea88"; }

.webicon-google-boxed:before {
  content: "\ea89"; }

.webicon-google3:before {
  content: "\ea8a"; }

.webicon-google-plus:before {
  content: "\ea8b"; }

.webicon-google-plus2:before {
  content: "\ea8c"; }

.webicon-google-plus3:before {
  content: "\ea8d"; }

.webicon-facebook:before {
  content: "\ea90"; }

.webicon-facebook-boxed:before {
  content: "\ea91"; }

.webicon-instagram:before {
  content: "\ea92"; }

.webicon-twitter:before {
  content: "\ea96"; }

.webicon-vine:before {
  content: "\ea97"; }

.webicon-rss:before {
  content: "\ea9b"; }

.webicon-rss-boxed:before {
  content: "\ea9c"; }

.webicon-youtube:before {
  content: "\ea9d"; }

.webicon-vimeo:before {
  content: "\eaa0"; }

.webicon-vimeo-boxed:before {
  content: "\eaa1"; }

.webicon-flickr:before {
  content: "\eaa3"; }

.webicon-flickr2:before {
  content: "\eaa4"; }

.webicon-flickr-boxed:before {
  content: "\eaa5"; }

.webicon-flickr4:before {
  content: "\eaa6"; }

.webicon-behance:before {
  content: "\eaa8"; }

.webicon-behance-boxed:before {
  content: "\eaa9"; }

.webicon-dropbox:before {
  content: "\eaae"; }

.webicon-github:before {
  content: "\eab0"; }

.webicon-basecamp:before {
  content: "\eab2"; }

.webicon-wordpress:before {
  content: "\eab4"; }

.webicon-tumblr:before {
  content: "\eab9"; }

.webicon-tumblr-boxed:before {
  content: "\eaba"; }

.webicon-tux:before {
  content: "\eabd"; }

.webicon-apple:before {
  content: "\eabe"; }

.webicon-finder:before {
  content: "\eabf"; }

.webicon-android:before {
  content: "\eac0"; }

.webicon-reddit:before {
  content: "\eac6"; }

.webicon-linkedin-boxed:before {
  content: "\eac9"; }

.webicon-linkedin:before {
  content: "\eaca"; }

.webicon-lastfm:before {
  content: "\eacb"; }

.webicon-lastfm-boxed:before {
  content: "\eacc"; }

.webicon-delicious:before {
  content: "\eacd"; }

.webicon-pinterest:before {
  content: "\ead1"; }

.webicon-pinterest2:before {
  content: "\ead2"; }

.webicon-yelp:before {
  content: "\ead7"; }

.webicon-paypal:before {
  content: "\ead8"; }

.webicon-file-pdf:before {
  content: "\eadf"; }

.webicon-file-openoffice:before {
  content: "\eae0"; }

.webicon-file-word:before {
  content: "\eae1"; }

.webicon-file-excel:before {
  content: "\eae2"; }

.webicon-libreoffice:before {
  content: "\eae3"; }

.webicon-up:before {
  content: "\f0d8"; }

.webicon-info2:before {
  content: "\f129"; }

.webicon-peace:before {
  content: "\f25b"; }

.webicon-snapchat:before {
  content: "\f2ac"; }

html, body {
  width: -webkit-calc(100% - 0px); }

html {
  font-size: 16px;
  position: relative;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar; }
  @media (min-width: 1921px) {
    html {
      font-size: 16px; } }

body {
  margin: 0;
  padding: 0;
  font-family: "Oswald", serif;
  font-size: 120%;
  color: #231f20;
  background-color: #fff; }
  @media (min-width: 1921px) {
    body {
      font-size: 150%; } }

#tinymce {
  background-color: #fff;
  background-image: none; }

body, button, input, select, textarea {
  line-height: 1.618; }

.wrap, .container {
  position: relative; }

/* ==================================================
   Code and Pre
   ================================================== */
code, pre {
  padding: 0 3px 2px;
  color: #232527;
  font-size: 0.75rem;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

code {
  padding: 2px 4px;
  border: 1px solid #dde4e9;
  background-color: #f6f6f6;
  color: #232527;
  white-space: nowrap; }

pre {
  display: block;
  margin: 30px;
  padding: 15px;
  border: 1px solid #dde4e9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #fcfcfc;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 12px;
  line-height: 20px; }

pre code {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  white-space: pre;
  white-space: pre-wrap; }

.pre-scrollable {
  overflow-y: scroll;
  max-height: 340px; }

.badge, .dropdown-header, .dropdown-menu > li > a, .input-group-addon, .input-group-btn, .label, .text-nowrap, code {
  white-space: normal; }

object, iframe {
  max-width: 100%; }

/* ==================================================
   Typography
   ================================================== */
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 10px 0;
  font-weight: 400;
  font-family: "Oswald", serif;
  line-height: 1.3;
  color: #939598;
  -moz-font-feature-settings: "lnum" 1;
  -ms-font-feature-settings: "lnum" 1;
  -o-font-feature-settings: "lnum" 1;
  -webkit-font-feature-settings: "lnum" 1;
  font-feature-settings: "lnum" 1;
  font-variant-numeric: lining-nums; }
  h1 a, h1 a:hover, h2 a, h2 a:hover, h3 a, h3 a:hover, h4 a, h4 a:hover, h5 a, h5 a:hover, h6 a, h6 a:hover {
    text-decoration: none; }

h1 {
  font-size: 2rem; }

h1.page-title {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.05;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    h1.page-title {
      margin-bottom: 35px;
      font-size: 1.25rem; } }

.search .page-header h1 {
  text-align: center; }

h2.entry-title {
  margin-bottom: 0;
  padding: 0;
  font-size: 1.375rem;
  background-color: transparent; }

h1.entry-title,
h2.entry-title {
  line-height: 1; }

h2.page-subhead {
  font-weight: 300;
  color: #231f20; }
  @media (min-width: 992px) {
    h2.page-subhead {
      margin-bottom: 55px; } }

h3 {
  font-size: 1.75rem;
  font-weight: 300;
  color: #939598; }

h4 {
  font-size: 1.25rem;
  font-weight: 400; }

@inlcude media-breakpoint-up(md) {
  h4 {
    font-weight: 700; } }

h5 {
  font-size: 1.125rem;
  font-weight: 400; }

h6 {
  font-size: 0.9375rem;
  font-weight: 700;
  text-transform: uppercase; }

small {
  font-size: 0.75rem; }
  @media (min-width: 1921px) {
    small {
      font-size: 0.851rem !important; } }

p {
  margin: 0 0 25px; }
  p:empty {
    display: none; }

p, li, ol {
  font-weight: 300;
  line-height: 1.4; }

ul,
ol {
  margin-bottom: 25px; }

.nolist {
  list-style: none;
  margin: 0;
  padding: 0; }
  .nolist li {
    margin: 0;
    padding: 0; }

hr {
  clear: both;
  border-top: solid #e5e5e5;
  border-width: 1px 0 0;
  margin: 35px 0;
  height: 0; }

hr.hr-small {
  margin: 15px 0; }

hr.hr-clear {
  border-top-color: transparent; }

.rwd-break {
  display: none; }
  @media (min-width: 768px) {
    .rwd-break {
      display: block; } }

.black-text {
  color: #231f20; }

.white-text {
  color: #fff; }

.gray-text {
  color: #939598; }

.red-text {
  color: #e51e25; }

blockquote {
  padding: 15px 25px;
  margin: 25px auto;
  color: #231f20;
  background-color: #f6f5f6;
  border-left: 0 solid #939598; }
  @media (min-width: 992px) {
    blockquote {
      margin: 50px auto;
      padding: 35px; } }
  blockquote span {
    font-size: 1.5rem;
    color: #939598; }

::selction {
  background: rgba(68, 159, 172, 0.5); }

/* ==================================================
   Images
   ================================================== */
img {
  border: 0;
  outline: none; }

.wrap figure,
.wrap img {
  max-width: 100% !important;
  height: auto; }

.full-width-img {
  width: 100%;
  height: auto; }

.img-overlay {
  background-color: #414042; }
  .img-overlay img {
    opacity: 0.3; }

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: #449fac; }

figure.vid-thumb {
  overflow: hidden; }
  figure.vid-thumb img {
    display: block;
    width: 100%;
    height: auto; }

figure.vid-thumb-16-9 img {
  margin: -9.375% 0; }

/* ==================================================
   Links
   ================================================== */
a {
  outline: none;
  border: 0;
  color: #939598;
  text-decoration: none;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;
  -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;
  -ms-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, opacity 0.15s ease-in-out; }
  a:hover, a:active, a:focus {
    outline: none;
    color: #e51e25;
    text-decoration: none; }

.back-to-top {
  margin: 0 auto;
  padding: 5px 0;
  font-size: 1.3125rem;
  font-weight: 700;
  text-align: center;
  color: #aca38d;
  background-color: #414042; }
  .back-to-top a {
    color: #aca38d;
    text-decoration: none;
    display: block;
    text-transform: uppercase; }
    .back-to-top a:hover {
      color: #fff; }

#toTop {
  display: none;
  text-decoration: none;
  position: fixed;
  bottom: 0;
  right: 25px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: #e51e25;
  border: 0;
  border-radius: 5px 5px 0 0;
  z-index: 5001;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  #toTop:hover {
    background-color: #939598; }

#toTopHover {
  display: none !important; }

#toTop:active, #toTop:focus {
  outline: none; }

/* ==================================================
   Animations
   ================================================== */
.hover-effect a {
  display: block;
  overflow: hidden;
  position: relative; }

.hover-effect img {
  -webkit-transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  -ms-transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1); }

.hover-effect:hover img {
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05); }

.corner-ribbon {
  padding: 5px 0;
  width: 200px;
  background: #231f20;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 1.1;
  font-family: "Oswald", serif;
  text-transform: uppercase;
  color: #e7e4d3;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }
  .corner-ribbon.sticky {
    position: fixed; }
  .corner-ribbon.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); }
  .corner-ribbon.top-right {
    top: 25px;
    right: -50px;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }
  .corner-ribbon.bottom-left {
    top: auto;
    bottom: 25px;
    left: -50px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }
  .corner-ribbon.bottom-right {
    top: auto;
    right: -50px;
    bottom: 25px;
    left: auto;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  .corner-ribbon.white {
    background: #fff;
    color: #231f20; }
  .corner-ribbon.black {
    background: #231f20; }
  .corner-ribbon.gray {
    background: #939598; }
  .corner-ribbon.blue {
    background: #449fac; }
  .corner-ribbon.light-blue {
    background: #bedddd;
    color: #41313f; }
  .corner-ribbon.purple {
    background: #41313f; }

.btn, .comment-form input[type="submit"], .search-form .search-submit,
.comment-form input[type=submit],
.search-form .search-submit {
  border-radius: 0;
  background: #41313f;
  font-family: "Oswald", serif;
  color: #f5f3e7;
  border: 1px solid #41313f; }

.btn-secondary:hover, .comment-form input:hover[type="submit"], .search-form .search-submit:hover,
.comment-form input[type=submit]:hover,
.search-form .search-submit:hover {
  color: #f5f3e7;
  background-color: #939598;
  border-color: #939598;
  cursor: pointer; }

.btn-secondary.active, .comment-form input.active[type="submit"], .search-form .active.search-submit,
.btn-secondary:active,
.comment-form input:active[type="submit"],
.search-form .search-submit:active,
.comment-form .show > input.dropdown-toggle[type=submit],
.comment-form input.active[type=submit],
.comment-form input[type=submit]:active,
.search-form .active.search-submit,
.search-form .search-submit:active,
.search-form .show > .dropdown-toggle.search-submit,
.show > .btn-secondary.dropdown-toggle,
.comment-form .show > input.dropdown-toggle[type="submit"],
.search-form .show > .dropdown-toggle.search-submit {
  background-color: #545558;
  border-color: #545558; }

.btn-secondary.focus, .comment-form input.focus[type="submit"], .search-form .focus.search-submit, .btn-secondary:focus, .comment-form input:focus[type="submit"], .search-form .search-submit:focus,
.comment-form input.focus[type=submit],
.comment-form input[type=submit]:focus,
.search-form .focus.search-submit,
.search-form .search-submit:focus {
  box-shadow: 0 0 0 0 #fff; }

.btn, .comment-form input[type="submit"], .search-form .search-submit {
  display: inline-block;
  width: auto;
  position: relative;
  padding: 12px 20px;
  text-align: center;
  font-family: "Oswald", serif;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  text-decoration: none !important;
  border: 1px solid #ccc;
  border-radius: 0 !important;
  text-transform: uppercase;
  white-space: normal; }

.btn:hover, .comment-form input:hover[type="submit"], .search-form .search-submit:hover, .btn:active, .comment-form input:active[type="submit"], .search-form .search-submit:active, .btn:visited, .comment-form input:visited[type="submit"], .search-form .search-submit:visited {
  color: #fff;
  text-decoration: none !important; }

.btn-sm, .btn-group-sm > .btn, .comment-form .btn-group-sm > input[type="submit"], .search-form .btn-group-sm > .search-submit {
  font-size: 0.75rem; }

.btn-lg, .btn-group-lg > .btn, .comment-form .btn-group-lg > input[type="submit"], .search-form .btn-group-lg > .search-submit {
  font-size: 1.5rem; }

.btn-default {
  border-color: gray;
  background-color: gray; }

.btn-red, .btn-red:focus {
  background-color: #e51e25 !important;
  border-color: #e51e25 !important;
  color: #fff !important; }

.btn-purple, .btn-purple:focus {
  background-color: #41313f !important;
  border-color: #41313f !important;
  color: #fff !important; }

.btn-blu, .btn-blu:focus {
  background-color: #449fac !important;
  border-color: #449fac !important;
  color: #fff !important; }

.btn-dark, .btn-dark:focus {
  background-color: #231f20 !important;
  border-color: #231f20 !important;
  color: #fff !important; }

.btn-hlw, .btn-hlw:focus {
  background-color: transparent !important;
  border-color: #fff !important;
  color: #231f20 !important; }

.btn-red:hover, .btn-red:active, .btn-red.active,
.btn-blu:hover, .btn-blu:active, .btn-blu.active,
.btn-purple:hover, .btn-purple:active, .btn-purple.active,
.btn-dark:hover, .btn-dark:active, .btn-dark.active,
.btn-hlw:hover, .btn-hlw:active, .btn-hlw.active,
.comment-form input[type=submit]:hover {
  background-color: transparent !important;
  border-color: #939598 !important;
  color: #939598 !important;
  outline: 0; }

.comment-list ol {
  list-style: none; }

.search-form label {
  font-weight: normal; }

.main {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  padding-left: 0;
  padding-right: 0; }
  @media (min-width: 576px) {
    .main {
      flex: 0 0 100%;
      max-width: 100%; }
      .sidebar-primary .main {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%; } }

.sidebar {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .sidebar {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; } }

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto; }

.aligncenter {
  display: block;
  margin: 15px auto;
  height: auto; }

.alignleft,
.alignright {
  margin-bottom: 15px;
  height: auto; }

@media (min-width: 576px) {
  .alignleft {
    float: left;
    margin-right: 15px; }
  .alignright {
    float: right;
    margin-left: 15px; } }

.banner {
  position: relative;
  width: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(35, 31, 32, 0.3); }
  .banner.sticky {
    background: rgba(255, 255, 255, 0.9) !important;
    animation: fading .5s ease-in-out;
    animation-fill-mode: both; }

@keyframes fading {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    .banner.sticky .brand {
      background-image: url(../images/dawson-lupul-red-black.svg) !important; }
    .banner.sticky ul#menu-main-navigation li a, .banner.sticky ul#menu-main-navigation li button {
      color: #231f20; }
      .banner.sticky ul#menu-main-navigation li a:hover, .banner.sticky ul#menu-main-navigation li button:hover {
        color: #939598; }
    .banner.sticky ul#menu-main-navigation li.active a {
      color: #939598; }
  .banner .nav-wrap {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
    min-height: 60px; }
    @media (min-width: 992px) {
      .banner .nav-wrap {
        min-height: 100px; } }
    @media (min-width: 1200px) {
      .banner .nav-wrap {
        min-height: 115px;
        padding-right: 0; } }
  .banner .brand {
    margin: 0;
    position: absolute;
    top: 1px;
    left: 50%;
    width: 100px;
    height: 59px;
    text-indent: -999em;
    background-image: url(../images/dawson-lupul-red-black.svg);
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    z-index: 101;
    transform: translateX(-50px); }
    @media (min-width: 992px) {
      .banner .brand {
        margin: 0;
        position: absolute;
        left: 15px;
        top: 30px;
        transform: translateX(0); } }
    @media (min-width: 1200px) {
      .banner .brand {
        left: 2.6vw;
        top: auto;
        bottom: 0;
        width: 177px;
        height: 105px; } }

.navbar-header {
  float: none;
  min-height: 60px; }
  @media (min-width: 992px) {
    .navbar-header {
      float: right; } }

.navbar-collapse {
  padding: 0;
  border-top: 0 solid transparent;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  @media (max-width: 991.98px) {
    .navbar-collapse {
      position: absolute;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.9); } }

.navbar-collapse.collapse {
  display: none !important;
  height: 0 !important;
  padding-bottom: 0;
  overflow: visible !important; }
  @media (min-width: 992px) {
    .navbar-collapse.collapse {
      display: block !important;
      height: auto !important; } }

.navbar-collapse.collapsing {
  display: block !important;
  background-color: transparent; }
  @media (max-width: 991.98px) {
    .navbar-collapse.collapsing {
      background-color: rgba(255, 255, 255, 0.9); } }

.navbar-collapse.show {
  display: block !important;
  height: auto !important; }

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: transparent;
  clear: both; }

ul#menu-main-navigation {
  list-style: none;
  margin: 0;
  padding: 0;
  flex-flow: column nowrap; }
  @media (min-width: 992px) {
    ul#menu-main-navigation {
      background-color: transparent;
      justify-content: flex-end;
      align-items: center;
      flex-flow: row nowrap; } }
  ul#menu-main-navigation li {
    float: left;
    margin: 0;
    padding: 0;
    position: relative;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out; }
    ul#menu-main-navigation li a, ul#menu-main-navigation li button {
      margin: 0;
      padding: 15px;
      display: block;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.618;
      color: #231f20;
      text-align: center;
      text-shadow: none;
      text-transform: uppercase;
      background: transparent;
      border: 0 solid #939598;
      border-width: 0;
      border-radius: 0;
      text-decoration: none !important;
      overflow: hidden;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      -ms-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out; }
      @media (min-width: 992px) {
        ul#menu-main-navigation li a, ul#menu-main-navigation li button {
          display: inline-block;
          margin: 0;
          padding: 0 35px;
          font-size: 1.125rem;
          line-height: 100px;
          text-align: center;
          color: #231f20;
          border-bottom: 0 solid #fff; } }
      @media (min-width: 1200px) {
        ul#menu-main-navigation li a, ul#menu-main-navigation li button {
          font-size: 1.25rem;
          line-height: 115px; } }
    ul#menu-main-navigation li .sub-menu {
      margin: 0;
      padding: 0; }
      @media (min-width: 576px) {
        ul#menu-main-navigation li .sub-menu {
          background-color: #312b2c; } }
      @media (min-width: 992px) {
        ul#menu-main-navigation li .sub-menu {
          display: none;
          position: absolute;
          left: 0;
          height: 0;
          z-index: 999;
          opacity: 0;
          width: 250px;
          background-color: rgba(255, 255, 255, 0.9);
          -webkit-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, opacity 2.5s ease-in-out, box-shadow 0.25s ease-in-out, visibility 0.25s ease-in-out;
          -moz-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, opacity 2.5s ease-in-out, box-shadow 0.25s ease-in-out, visibility 0.25s ease-in-out;
          -ms-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, opacity 2.5s ease-in-out, box-shadow 0.25s ease-in-out, visibility 0.25s ease-in-out;
          -o-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, opacity 2.5s ease-in-out, box-shadow 0.25s ease-in-out, visibility 0.25s ease-in-out;
          transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, opacity 2.5s ease-in-out, box-shadow 0.25s ease-in-out, visibility 0.25s ease-in-out; } }
      ul#menu-main-navigation li .sub-menu li {
        float: none;
        padding: 0;
        margin: 0;
        display: block;
        text-align: left; }
        ul#menu-main-navigation li .sub-menu li a {
          font-weight: 400;
          text-transform: capitalize;
          color: #939598;
          line-height: 1.2; }
          @media (min-width: 992px) {
            ul#menu-main-navigation li .sub-menu li a {
              padding: 10px 15px;
              font-size: 1rem;
              text-align: right;
              text-transform: uppercase;
              box-shadow: none !important; }
              ul#menu-main-navigation li .sub-menu li a:hover {
                color: #231f20 !important; } }
      @media (min-width: 992px) {
        ul#menu-main-navigation li .sub-menu li:hover {
          background-color: #939598; }
          ul#menu-main-navigation li .sub-menu li:hover a {
            color: #231f20; } }
    @media (min-width: 992px) {
      ul#menu-main-navigation li:hover .sub-menu {
        display: block;
        opacity: 1;
        height: auto; } }

ul#menu-main-navigation > li:hover a,
ul#menu-main-navigation > li > a:hover,
ul#menu-main-navigation > li.active > a,
ul#menu-main-navigation > li > button:hover,
ul#menu-main-navigation > li.active > button {
  position: relative;
  background-color: transparent;
  color: #939598; }
  @media (min-width: 992px) {
    ul#menu-main-navigation > li:hover a,
    ul#menu-main-navigation > li > a:hover,
    ul#menu-main-navigation > li.active > a,
    ul#menu-main-navigation > li > button:hover,
    ul#menu-main-navigation > li.active > button {
      box-shadow: inset 0 -4px 0 #231f20; } }

.home ul#menu-main-navigation > li > a:hover,
.home ul#menu-main-navigation > li.active > a,
.home ul#menu-main-navigation > li > button:hover,
.home ul#menu-main-navigation > li.active > button {
  color: #231f20; }
  @media (min-width: 992px) {
    .home ul#menu-main-navigation > li > a:hover,
    .home ul#menu-main-navigation > li.active > a,
    .home ul#menu-main-navigation > li > button:hover,
    .home ul#menu-main-navigation > li.active > button {
      box-shadow: inset 0 0 0 #231f20; } }

.nav > li > button:focus, .nav > li > button:hover,
.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent; }

.navbar-toggle {
  position: relative;
  float: right;
  padding: 0;
  margin: 20px 15px 0 0;
  display: block;
  margin-bottom: 0;
  background-color: transparent;
  background-image: none;
  border: 0 solid transparent;
  border-radius: 0; }
  @media (min-width: 992px) {
    .navbar-toggle {
      display: none; } }

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
  color: #992916; }

.navbar-default .navbar-toggle:focus span.glyphicon, .navbar-default .navbar-toggle:hover span.glyphicon {
  color: #992916; }

.hamburger {
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible; }
  .hamburger:active, .hamburger:focus {
    outline: 0;
    border: 0; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 30px;
  height: 21px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    height: 3px;
    background-color: #231f20;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -9px; }
  .hamburger-inner::after {
    bottom: -9px; }

/*
* Squeeze
*/
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.1s 0.14s ease, opacity 0.1s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.55, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease, opacity 0.1s 0.14s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.55, 1); }

.home .hamburger-inner, .home .hamburger-inner::before, .home .hamburger-inner::after {
  background-color: #fff; }

@media (min-width: 992px) {
  .home ul#menu-main-navigation li a, .home ul#menu-main-navigation li button {
    color: #fff !important; }
    .home ul#menu-main-navigation li a:hover, .home ul#menu-main-navigation li button:hover {
      color: #939598 !important; } }

.home ul#menu-main-navigation li .sub-menu li a {
  color: #231f20 !important; }

.home .banner {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  box-shadow: none; }
  .home .banner.sticky {
    box-shadow: 0 0 15px rgba(35, 31, 32, 0.3); }
    .home .banner.sticky .hamburger-inner, .home .banner.sticky .hamburger-inner::before, .home .banner.sticky .hamburger-inner::after {
      background-color: #231f20; }
    .home .banner.sticky ul#menu-main-navigation li a, .home .banner.sticky ul#menu-main-navigation li button {
      color: #231f20 !important; }
    .home .banner.sticky ul#menu-main-navigation li .sub-menu li a {
      color: #231f20 !important; }
  .home .banner .brand {
    background-image: url(../images/dawson-lupul.svg); }

footer.content-info {
  padding: 50px 0 35px;
  position: relative;
  background: #231f20;
  color: #939598; }
  footer.content-info a {
    text-decoration: none;
    color: #fff; }
    footer.content-info a:hover {
      color: #e51e25; }
  footer.content-info .footer-row > div {
    margin-bottom: 35px;
    padding-bottom: 35px;
    border-bottom: 1px solid #939598; }
  footer.content-info .footer-row:last-of-type > div {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0 solid #939598; }
  footer.content-info .footer-logo {
    margin: 50px auto 25px;
    width: 203px;
    height: 120px;
    text-indent: -999em;
    background-image: url(../images/dawson-lupul-red.svg);
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    z-index: 101; }
    @media (min-width: 768px) {
      footer.content-info .footer-logo {
        margin: 50px 0 0; } }
  @media (max-width: 1199.98px) {
    footer.content-info .footer-tout {
      margin-bottom: 15px; } }
  footer.content-info .footer-tout .webicon {
    font-size: 2.25rem; }
    @media (min-width: 992px) {
      footer.content-info .footer-tout .webicon {
        font-size: 3rem; } }
  footer.content-info .footer-tout h3 {
    margin: 0 0 0 10px;
    font-size: 1.25rem;
    font-weight: 300; }
    @media (min-width: 992px) {
      footer.content-info .footer-tout h3 {
        font-size: 1.75rem; } }
  footer.content-info h3.footer-headline {
    margin-bottom: 35px;
    font-size: 1.25rem;
    font-weight: 300;
    color: #939598;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      footer.content-info h3.footer-headline {
        font-size: 1.75rem; } }
  footer.content-info #menu-footer-navigation {
    text-align: center;
    margin: 0 auto;
    padding: 0; }
    @media (min-width: 768px) {
      footer.content-info #menu-footer-navigation {
        text-align: right; } }
    footer.content-info #menu-footer-navigation li {
      display: block;
      margin-left: 10px; }
      footer.content-info #menu-footer-navigation li a {
        font-size: 0.875rem;
        text-transform: uppercase;
        color: #fff; }
        footer.content-info #menu-footer-navigation li a:hover {
          color: #e51e25; }
    footer.content-info #menu-footer-navigation .sub-menu {
      margin: 0;
      padding: 0;
      display: inline-block; }
      footer.content-info #menu-footer-navigation .sub-menu li {
        display: inline-block; }
  footer.content-info .legal {
    font-size: 0.75rem; }

.page-header {
  margin-top: 25px; }
  @media (min-width: 992px) {
    .page-header {
      margin-top: 50px; } }

.home .content-container {
  padding-top: 0; }

.home section {
  position: relative;
  margin-bottom: 100px; }
  .home section:last-of-type {
    margin-bottom: 0; }

#home-intro {
  /*
	$navigation-height:115px;
	$navigation-height-md:100px;
	$navigation-height-sm:60px;
	*/
  margin-top: -60px;
  height: 100vh;
  overflow: hidden; }
  @media (min-width: 992px) {
    #home-intro {
      margin-top: -100px; } }
  @media (min-width: 1200px) {
    #home-intro {
      margin-top: -115px; } }
  #home-intro .slide-img {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: 50% 50%; }
  #home-intro .est {
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #fff;
    width: 149px;
    height: 60px;
    background-image: url(../images/est-1995.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 60px 17px;
    transform: translateX(-75px);
    z-index: 997; }

#home-content h2 {
  margin: 0 auto 35px;
  font-size: 1.875rem;
  color: #231f20; }
  @media (min-width: 992px) {
    #home-content h2 {
      font-size: 3rem; } }

#home-content p {
  font-size: 1.5rem;
  line-height: 2; }
  @media (min-width: 992px) {
    #home-content p {
      font-size: 1.75rem; } }

#home-featured h3 {
  margin: 0;
  padding-bottom: 10px;
  font-size: 1.375rem;
  text-transform: uppercase;
  border-bottom: 5px solid #231f20; }

@media (max-width: 991.98px) {
  #home-featured div[class^=col-] {
    margin-bottom: 25px; } }

.testimonial {
  margin-top: 100px;
  padding: 30px 15px;
  background: -moz-linear-gradient(top, rgba(35, 31, 32, 0.3) 0%, rgba(35, 31, 32, 0) 40%);
  background: -webkit-linear-gradient(top, rgba(35, 31, 32, 0.3) 0%, rgba(35, 31, 32, 0) 40%);
  background: linear-gradient(to bottom, rgba(35, 31, 32, 0.3) 0%, rgba(35, 31, 32, 0) 40%); }
  @media (min-width: 1200px) {
    .testimonial {
      padding: 50px 8.33333333vw; } }
  .testimonial .test-quote {
    font-weight: 300;
    font-size: 1.125rem;
    color: #231f20; }
    @media (min-width: 992px) {
      .testimonial .test-quote {
        font-size: 1.5rem; } }
    .testimonial .test-quote span {
      color: #939598;
      font-weight: 400;
      font-size: 1.5rem; }
      @media (min-width: 992px) {
        .testimonial .test-quote span {
          font-size: 1.875rem; } }
  .testimonial .attrib-name {
    margin-right: 15px;
    text-align: right;
    line-height: 1.2; }
    .testimonial .attrib-name .an-name {
      color: #231f20;
      font-size: 1.5rem; }
    .testimonial .attrib-name .an-info {
      color: #939598;
      font-size: 0.8125rem; }
  .testimonial .attrib-image {
    margin-top: 15px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden; }
    @media (min-width: 992px) {
      .testimonial .attrib-image {
        margin-top: 0; } }
    @media (min-width: 1200px) {
      .testimonial .attrib-image {
        width: 150px;
        height: 150px; } }
    .testimonial .attrib-image img {
      max-width: 100%;
      height: auto; }

.testimonial-spacer {
  height: 0;
  margin-top: 25px; }
  @media (min-width: 992px) {
    .testimonial-spacer {
      margin-top: 45px; } }

.album-item {
  margin-bottom: 15px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out; }
  @media (min-width: 992px) {
    .album-item {
      margin-bottom: 30px; } }
  .album-item:hover .cover {
    opacity: 1; }

.cover {
  position: absolute;
  left: 0;
  bottom: 10px;
  padding: 5px 15px;
  width: auto;
  background-color: rgba(35, 31, 32, 0.777);
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  opacity: 1;
  text-align: left; }
  @media (min-width: 992px) {
    .cover {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(35, 31, 32, 0.777);
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      -ms-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
      opacity: 0;
      text-align: center; } }
  .cover h3 {
    font-size: 1.375rem;
    font-weight: 300;
    letter-spacing: 1px;
    color: #fff; }
    @media (max-width: 1199.98px) {
      .cover h3 {
        margin: 0; } }
  .cover .album-arrow {
    display: none; }
    @media (min-width: 992px) {
      .cover .album-arrow {
        display: inline-block;
        text-align: center;
        line-height: 20px;
        width: 20px;
        border: 1px solid #fff;
        border-radius: 50%;
        font-size: 0.75rem;
        color: #fff; } }

#social-share {
  margin: 25px auto;
  font-size: 0.875rem; }
  @media (min-width: 992px) {
    #social-share {
      margin-bottom: 50px; } }
  #social-share a {
    display: inline-block;
    margin: 0 10px;
    color: #231f20; }

.gallery-content {
  margin-top: 25px; }
  @media (min-width: 992px) {
    .gallery-content {
      margin-top: 50px; } }
  .gallery-content .gallery-item {
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .gallery-content .gallery-item {
        margin-bottom: 30px; } }

body#tinymce {
  margin: 12px !important; }
