// IE 11 flexbox fix.
// TODO: Remove when fixed in bootstrap.
// https://github.com/twbs/bootstrap/issues/17310
$enable-flex: true;
@if $enable-flex {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            @for $size from 1 through $grid-columns {
                .col-#{$breakpoint}-#{$size} {
                    max-width: percentage($size / $grid-columns);
                }
            }
        }
    }
}

//
// Mixins
//

.clearfix {
	&:after {
		content:'';
		display:table;
		clear:both; 
	}
	*zoom:1;
}

@mixin clearfix() {
	&:after {
		content:'';
		display:table;
		clear:both; 
	}
	*zoom:1;
}

@mixin transition($prop_time_easing...){ // for multiple arguments as PROP1 TIME1 EASING1, PROP2 TIME2 EASING2, etc.
	-webkit-transition:$prop_time_easing;
	   -moz-transition:$prop_time_easing;
	    -ms-transition:$prop_time_easing;
	     -o-transition:$prop_time_easing;
	        transition:$prop_time_easing;
}

@mixin rounded($value){
	-webkit-border-radius:$value;
	-moz-border-radius:$value;
	border-radius:$value;
}

/*============================================================================
  Prefixer mixin for generating vendor prefixes:
    - Based on https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_prefixer.scss
    - Usage:

      // Input:
      .element {
        @include prefixer(transform, scale(1), ms webkit spec);
      }

      // Output:
      .element {
        -ms-transform:scale(1);
        -webkit-transform:scale(1);
        transform:scale(1);
      }
==============================================================================*/

@mixin prefixer($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    @if $prefix == webkit {
      -webkit-#{$property}:$value;
    } @else if $prefix == moz {
      -moz-#{$property}:$value;
    } @else if $prefix == ms {
      -ms-#{$property}:$value;
    } @else if $prefix == o {
      -o-#{$property}:$value;
    } @else if $prefix == spec {
      #{$property}:$value;
    } @else  {
      @warn "Unrecognized prefix:#{$prefix}";
    }
  }
}

@mixin transform($transform) {
  @include prefixer(transform, $transform, ms webkit spec);
}

@mixin user-select($value:none) {
  @include prefixer(user-select, $value, webkit moz ms spec);
}

@mixin backface($visibility:hidden) {
  @include prefixer(backface-visibility, $visibility, webkit spec);
}

@function em($target, $context:$base-font-size) {
  @if $target == 0 {
    @return 0;
  }
  @return $target / $context + 0rem;
}

@function color-control($color) {
  @if (lightness( $color ) > 40) {
    @return #000;
  }
  @else {
    @return #fff;
  }
}

// HEX to RGBA backgorund
@mixin rgba-background($hexcolor, $opacity) {
	background-color:$hexcolor; // fallback, do we still do that?
	background-color:rgba($hexcolor, $opacity); 
}


// ==========================================================================
// Helper classes
// ==========================================================================

.ir {
    background-color:transparent;
    border:0;
    overflow:hidden;
    *text-indent:-9999px;
}

.ir:before {
    content:"";
    display:block;
    width:0;
    height:150%;
}

.hidden {
    display:none !important;
    visibility:hidden;
}

.visuallyhidden {
    border:0;
    clip:rect(0 0 0 0);
    height:1px;
    margin:-1px;
    overflow:hidden;
    padding:0;
    position:absolute;
    width:1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip:auto;
    height:auto;
    margin:0;
    overflow:visible;
    position:static;
    width:auto;
}

.invisible {
    visibility:hidden;
}


@include media-breakpoint-down(lg) {
	.mobile-hide {
		display:none;
	}
}

@include media-breakpoint-up(lg) {
	.desktop-hide {
		display:none;
	}
}

// layouts
.top-center {
	position:absolute;
	top:0;
	width:100%;
	text-align:center;
}

.bottom-center {
	position:absolute;
	bottom:0;
	width:100%;
	text-align:center;
}

.middle-center {
	position: absolute;
    top: 50%;
    left: 50%;
	width:100%;
	height:auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.rwd-br {
	display:none;
	
	@include media-breakpoint-up(lg){
		display:inline-block;
	}
}

// ==========================================================================
// Website Icons
// ==========================================================================
// usage:<span class="webicon webicon-facebook"></span>

@font-face {
  font-family: 'webicon';
  src:  url('#{$icomoon-font-path}/webicon.eot?ynccn3');
  src:  url('#{$icomoon-font-path}/webicon.eot?ynccn3#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/webicon.ttf?ynccn3') format('truetype'),
    url('#{$icomoon-font-path}/webicon.woff?ynccn3') format('woff'),
    url('#{$icomoon-font-path}/webicon.svg?ynccn3#webicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="webicon-"], [class*=" webicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'webicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}

.webicon-play {
  &:before {
    content: unicode($webicon-play); 
  }
}
.webicon-phone {
  &:before {
    content: unicode($webicon-phone); 
  }
}
.webicon-phone-hang-up {
  &:before {
    content: unicode($webicon-phone-hang-up); 
  }
}
.webicon-display {
  &:before {
    content: unicode($webicon-display); 
  }
}
.webicon-mobile2 {
  &:before {
    content: unicode($webicon-mobile2); 
  }
}
.webicon-checkbox-checked {
  &:before {
    content: unicode($webicon-checkbox-checked); 
  }
}
.webicon-checkbox-unchecked {
  &:before {
    content: unicode($webicon-checkbox-unchecked); 
  }
}
.webicon-radio-checked {
  &:before {
    content: unicode($webicon-radio-checked); 
  }
}
.webicon-radio-checked2 {
  &:before {
    content: unicode($webicon-radio-checked2); 
  }
}
.webicon-radio-unchecked {
  &:before {
    content: unicode($webicon-radio-unchecked); 
  }
}
.webicon-mail {
  &:before {
    content: unicode($webicon-mail); 
  }
}
.webicon-mail2 {
  &:before {
    content: unicode($webicon-mail2); 
  }
}
.webicon-mail3 {
  &:before {
    content: unicode($webicon-mail3); 
  }
}
.webicon-mail4 {
  &:before {
    content: unicode($webicon-mail4); 
  }
}
.webicon-mail_outline {
  &:before {
    content: unicode($webicon-mail_outline); 
  }
}
.webicon-insert_invitation {
  &:before {
    content: unicode($webicon-insert_invitation); 
  }
}
.webicon-keyboard_arrow_down {
  &:before {
    content: unicode($webicon-keyboard_arrow_down); 
  }
}
.webicon-keyboard_arrow_left {
  &:before {
    content: unicode($webicon-keyboard_arrow_left); 
  }
}
.webicon-keyboard_arrow_right {
  &:before {
    content: unicode($webicon-keyboard_arrow_right); 
  }
}
.webicon-keyboard_arrow_up {
  &:before {
    content: unicode($webicon-keyboard_arrow_up); 
  }
}
.webicon-close {
  &:before {
    content: unicode($webicon-close); 
  }
}
.webicon-pdf {
  &:before {
    content: unicode($webicon-pdf); 
  }
}
.webicon-talk {
  &:before {
    content: unicode($webicon-talk); 
  }
}
.webicon-houzz {
  &:before {
    content: unicode($webicon-houzz); 
  }
}
.webicon-zoom-in {
  &:before {
    content: unicode($webicon-zoom-in); 
  }
}
.webicon-enlarge {
  &:before {
    content: unicode($webicon-enlarge); 
  }
}
.webicon-plus {
  &:before {
    content: unicode($webicon-plus); 
  }
}
.webicon-minus {
  &:before {
    content: unicode($webicon-minus); 
  }
}
.webicon-info {
  &:before {
    content: unicode($webicon-info); 
  }
}
.webicon-cancel-circle {
  &:before {
    content: unicode($webicon-cancel-circle); 
  }
}
.webicon-blocked {
  &:before {
    content: unicode($webicon-blocked); 
  }
}
.webicon-cross {
  &:before {
    content: unicode($webicon-cross); 
  }
}
.webicon-checkmark {
  &:before {
    content: unicode($webicon-checkmark); 
  }
}
.webicon-checkmark-hollow {
  &:before {
    content: unicode($webicon-checkmark-hollow); 
  }
}
.webicon-arrow-up2 {
  &:before {
    content: unicode($webicon-arrow-up2); 
  }
}
.webicon-arrow-right2 {
  &:before {
    content: unicode($webicon-arrow-right2); 
  }
}
.webicon-arrow-down2 {
  &:before {
    content: unicode($webicon-arrow-down2); 
  }
}
.webicon-arrow-left2 {
  &:before {
    content: unicode($webicon-arrow-left2); 
  }
}
.webicon-circle-up {
  &:before {
    content: unicode($webicon-circle-up); 
  }
}
.webicon-circle-right {
  &:before {
    content: unicode($webicon-circle-right); 
  }
}
.webicon-circle-down {
  &:before {
    content: unicode($webicon-circle-down); 
  }
}
.webicon-circle-left {
  &:before {
    content: unicode($webicon-circle-left); 
  }
}
.webicon-terminal {
  &:before {
    content: unicode($webicon-terminal); 
  }
}
.webicon-share2 {
  &:before {
    content: unicode($webicon-share2); 
  }
}
.webicon-google {
  &:before {
    content: unicode($webicon-google); 
  }
}
.webicon-google-boxed {
  &:before {
    content: unicode($webicon-google-boxed); 
  }
}
.webicon-google3 {
  &:before {
    content: unicode($webicon-google3); 
  }
}
.webicon-google-plus {
  &:before {
    content: unicode($webicon-google-plus); 
  }
}
.webicon-google-plus2 {
  &:before {
    content: unicode($webicon-google-plus2); 
  }
}
.webicon-google-plus3 {
  &:before {
    content: unicode($webicon-google-plus3); 
  }
}
.webicon-facebook {
  &:before {
    content: unicode($webicon-facebook); 
  }
}
.webicon-facebook-boxed {
  &:before {
    content: unicode($webicon-facebook-boxed); 
  }
}
.webicon-instagram {
  &:before {
    content: unicode($webicon-instagram); 
  }
}
.webicon-twitter {
  &:before {
    content: unicode($webicon-twitter); 
  }
}
.webicon-vine {
  &:before {
    content: unicode($webicon-vine); 
  }
}
.webicon-rss {
  &:before {
    content: unicode($webicon-rss); 
  }
}
.webicon-rss-boxed {
  &:before {
    content: unicode($webicon-rss-boxed); 
  }
}
.webicon-youtube {
  &:before {
    content: unicode($webicon-youtube); 
  }
}
.webicon-vimeo {
  &:before {
    content: unicode($webicon-vimeo); 
  }
}
.webicon-vimeo-boxed {
  &:before {
    content: unicode($webicon-vimeo-boxed); 
  }
}
.webicon-flickr {
  &:before {
    content: unicode($webicon-flickr); 
  }
}
.webicon-flickr2 {
  &:before {
    content: unicode($webicon-flickr2); 
  }
}
.webicon-flickr-boxed {
  &:before {
    content: unicode($webicon-flickr-boxed); 
  }
}
.webicon-flickr4 {
  &:before {
    content: unicode($webicon-flickr4); 
  }
}
.webicon-behance {
  &:before {
    content: unicode($webicon-behance); 
  }
}
.webicon-behance-boxed {
  &:before {
    content: unicode($webicon-behance-boxed); 
  }
}
.webicon-dropbox {
  &:before {
    content: unicode($webicon-dropbox); 
  }
}
.webicon-github {
  &:before {
    content: unicode($webicon-github); 
  }
}
.webicon-basecamp {
  &:before {
    content: unicode($webicon-basecamp); 
  }
}
.webicon-wordpress {
  &:before {
    content: unicode($webicon-wordpress); 
  }
}
.webicon-tumblr {
  &:before {
    content: unicode($webicon-tumblr); 
  }
}
.webicon-tumblr-boxed {
  &:before {
    content: unicode($webicon-tumblr-boxed); 
  }
}
.webicon-tux {
  &:before {
    content: unicode($webicon-tux); 
  }
}
.webicon-apple {
  &:before {
    content: unicode($webicon-apple); 
  }
}
.webicon-finder {
  &:before {
    content: unicode($webicon-finder); 
  }
}
.webicon-android {
  &:before {
    content: unicode($webicon-android); 
  }
}
.webicon-reddit {
  &:before {
    content: unicode($webicon-reddit); 
  }
}
.webicon-linkedin-boxed {
  &:before {
    content: unicode($webicon-linkedin-boxed); 
  }
}
.webicon-linkedin {
  &:before {
    content: unicode($webicon-linkedin); 
  }
}
.webicon-lastfm {
  &:before {
    content: unicode($webicon-lastfm); 
  }
}
.webicon-lastfm-boxed {
  &:before {
    content: unicode($webicon-lastfm-boxed); 
  }
}
.webicon-delicious {
  &:before {
    content: unicode($webicon-delicious); 
  }
}
.webicon-pinterest {
  &:before {
    content: unicode($webicon-pinterest); 
  }
}
.webicon-pinterest2 {
  &:before {
    content: unicode($webicon-pinterest2); 
  }
}
.webicon-yelp {
  &:before {
    content: unicode($webicon-yelp); 
  }
}
.webicon-paypal {
  &:before {
    content: unicode($webicon-paypal); 
  }
}
.webicon-file-pdf {
  &:before {
    content: unicode($webicon-file-pdf); 
  }
}
.webicon-file-openoffice {
  &:before {
    content: unicode($webicon-file-openoffice); 
  }
}
.webicon-file-word {
  &:before {
    content: unicode($webicon-file-word); 
  }
}
.webicon-file-excel {
  &:before {
    content: unicode($webicon-file-excel); 
  }
}
.webicon-libreoffice {
  &:before {
    content: unicode($webicon-libreoffice); 
  }
}
.webicon-up {
  &:before {
    content: unicode($webicon-up); 
  }
}
.webicon-info2 {
  &:before {
    content: unicode($webicon-info2); 
  }
}
.webicon-peace {
  &:before {
    content: unicode($webicon-peace); 
  }
}
.webicon-snapchat {
  &:before {
    content: unicode($webicon-snapchat); 
  }
}

// ==========================================================================
//   Base
// ==========================================================================
html, body {
	width: -webkit-calc(100% - 0px); 
}

html {
	font-size:$base-font-size; // base font size = 16px
	position:relative;
	min-height:100%;
	-webkit-text-size-adjust:100%; 
	-ms-text-size-adjust:100%;
	-ms-overflow-style:scrollbar; // IE scrollbar hack
	
	@media (min-width:1921px ){
		font-size:$base-font-size; // base font size = 16px
	}
}

body {
	margin:0; 
	padding:0; 
	font-family:$font-stack;
	font-size:120%; // base font size = 16px
	color: $black;
	background-color:$white;
	
	@media (min-width:1921px){
		font-size:150%; 
	}
}

#tinymce {
	background-color:#fff;
	background-image:none;
}

body, button, input, select, textarea {
	line-height:1.618; 
}

.wrap, .container {
	position:relative;
}

/* ==================================================
   Code and Pre
   ================================================== */
code, pre {
	padding:0 3px 2px;
	color:#232527;
	font-size:em(12px);
	font-family:Monaco, Menlo, Consolas, "Courier New", monospace;
	-webkit-border-radius:3px;
       -moz-border-radius:3px; 
	        border-radius:3px;
}

code {
	padding:2px 4px; 
	border:1px solid #dde4e9; 
	background-color:#f6f6f6; 
	color:#232527;
	white-space:nowrap;
}

pre {
  	display:block;
  	margin:30px;
  	padding:15px;
  	border:1px solid #dde4e9;
  	-webkit-border-radius:4px;
       -moz-border-radius:4px;
            border-radius:4px;
  	background-color:#fcfcfc;
  	white-space:pre;
  	white-space:pre-wrap;
  	word-wrap:break-word;
  	word-break:break-all;
  	font-size:12px;
  	line-height:20px;
}

pre code {
  	padding:0;
  	border:0;
  	background-color:transparent;
  	color:inherit;
  	white-space:pre;
  	white-space:pre-wrap;
}

.pre-scrollable {
  	overflow-y:scroll;
  	max-height:340px;
}

.badge, .dropdown-header, .dropdown-menu>li>a, .input-group-addon, .input-group-btn, .label, .text-nowrap, code {
    white-space:normal;
}

object, iframe {
	max-width:100%;
	// height:auto;
}


/* ==================================================
   Typography
   ================================================== */
h1, h2, h3, h4, h5, h6 {
	margin:0 0 10px 0; 
	font-weight:400; 
	font-family:$font-stack;
	line-height:1.3;
	color:$gray;
	-moz-font-feature-settings:"lnum" 1; 
	-ms-font-feature-settings:"lnum" 1; 
	-o-font-feature-settings:"lnum" 1; 
	-webkit-font-feature-settings:"lnum" 1; 
	font-feature-settings:"lnum" 1;
	font-variant-numeric: lining-nums;
	
	a, a:hover {
		text-decoration:none;
	}
}

h1 {
	font-size:em(32px);
}

h1.page-title {
  font-size: em(18px);
  font-weight: 300;
  line-height: 1.05;
  text-transform: uppercase;
  
  @include media-breakpoint-up(sm) {
    // font-size:calc(3.51vw + 5.125px); // y = mx + b; m = (y2 - y1)/(x2 - x1); b = y1 - m * x1
  }
  
  @include media-breakpoint-up(lg) {
    margin-bottom:35px;
    font-size:em(20px);
  }
}

.search .page-header h1 {
  text-align:center;
}

h2.entry-title {
  margin-bottom:0;
  padding:0;
  font-size:em(22px);
  background-color:transparent;
}

h1.entry-title,
h2.entry-title {
  line-height:1;
}

h2.page-subhead {
  font-weight: 300;
  color: $black;
  @include media-breakpoint-up(lg) {
    margin-bottom: 55px;
  }
}

h3 {
	font-size:em(28px);
  font-weight: 300;
	color:$gray;
}

h4 {
	font-size:em(20px);
	font-weight:400;
	
	@inlcude media-breakpoint-up(md){
		font-weight:700;
	}
}

h5 {
	font-size:em(18px);
	font-weight:400;
}

h6 {
	font-size:em(15px); 
	font-weight:700;
	text-transform:uppercase;
}

small {
	font-size:em(12px);
	// font-family:$sans-serif;
	
	@media (min-width:1921px ){
		font-size:.851rem!important;
	}
}

p {
	margin:0 0 25px;
	
	&:empty {
		display:none;
	}
}

p,li,ol {
	// font-family:$font-stack;
	// font-size: em(20px);
  font-weight: 300;
	line-height:1.4;
	
	@media (min-width:1921px ){
		// font-size:1.5rem!important;
	}
}

ul,
ol {
	margin-bottom:25px;
}

.nolist {
	list-style:none;
	margin:0;
	padding:0;
	
	li {
		margin:0;
		padding:0;
	}
}

hr {
	clear:both;
    border-top:solid #e5e5e5;
    border-width:1px 0 0;
    margin:35px 0;
    height:0;
}

hr.hr-small {
	margin:15px 0;
}

hr.hr-clear {
	border-top-color:transparent;
}

.rwd-break { 
	display:none;
	
	@include media-breakpoint-up(md) {
		display:block;
	}	
}

.black-text { color:$black; }
.white-text { color:$white; }
.gray-text { color:$gray; }
.red-text { color:$red; }

blockquote {
	padding: 15px 25px;
  margin: 25px auto ;
  // font-size:em(20px);
  // font-weight:700;
  // font-style: italic;
  // text-align: center;
  color: $black;
  background-color: #f6f5f6;
  
  border-left: 0 solid $gray;
  
  @include media-breakpoint-up(md) {
    // font-size:em(28px);
  }
  @include media-breakpoint-up(lg) {
    margin: 50px auto;
    padding: 35px;
	}
	
	span {
		font-size: em(24px);
		// font-family: $sans-serif;
		// font-weight: 400;
		// font-style: normal;
		color: $gray;
	}
}

::selction {
	background: rgba($blue, 0.5);
}

/* ==================================================
   Images
   ================================================== */
img {
	border:0;
	outline:none;
}

.wrap figure,
.wrap img {
	max-width:100% !important;
	height:auto;
}

.full-width-img {
	width:100%;
	height:auto;
}

.img-overlay {
	background-color:$dark-gray;
	
	img {
		opacity:0.3;
	}
}

// Add a hover state for linked versions only
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: $link-color;
}

figure {

	&.vid-thumb {
		overflow:hidden;

		img {
			display:block;
			width:100%;
			height:auto;
		}
	}
	
	&.vid-thumb-16-9 {

		img {
			margin:-9.375% 0;
		}
	}
}

/* ==================================================
   Links
   ================================================== */
a { 
	outline:none; 
	border:0;
	color:$gray;
	// font-weight:700;
	text-decoration:none;
  @include transition(color .15s ease-in-out, background-color .15s ease-in-out, opacity .15s ease-in-out);

  &:hover,
  &:active,
  &:focus {
    outline:none; 
    color:$red; 
    text-decoration:none;
    // text-decoration:none;
  }
}

.back-to-top {

  margin:0 auto;
  padding:5px 0;
  font-size:em(21px);
  font-weight:700;
  text-align:center;
  color:$light-gray;
  background-color:$dark-gray;
  
  a {
    color:$light-gray;
    text-decoration:none;
    display:block;
    text-transform:uppercase;
    
    &:hover {
      color:$white;
    }
  }
  
}

#toTop {
  display: none;
  text-decoration: none;
  position: fixed;
  bottom: 0;
  right: 25px;
  font-size: em(16px);
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  // overflow:hidden;
  width: 30px;
  height: 30px;
  // text-indent:100%;
  color: $white;
  background-color: $red;
  border: 0;
  border-radius: 5px 5px 0 0;
  z-index: 5001;
	@include transition(all .2s ease-in-out);
  // background:url(../img/ui.totop.png) no-repeat left top;

  &:hover {
    background-color: $gray;
  }
}

#toTopHover {
  display:none!important;
}

#toTop:active, #toTop:focus {
  outline:none;
}

/* ==================================================
   Animations
   ================================================== */
.hover-effect{
	a {
		display:block;
		overflow:hidden;
    position: relative;
	}
		
	& img {
		@include transition(opacity 0.25s ease-in-out, transform 0.25s ease-in-out);
		@include transform(scale(1));
	}

	&:hover img {
		@include transform(scale(1.05));
	}
}