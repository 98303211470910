///////////////////////////////////////////////////////////
// Typography
///////////////////////////////////////////////////////////

$base-font-size: 	16px; // Henceforth known as 1em
$font-stack: 		'Oswald', serif;
$serif: 			'Rozha One', serif;
$sans-serif: 		$font-stack;
$icon-font-path: 	"../fonts/"; // Glyphicons font path

///////////////////////////////////////////////////////////
// Grid settings
///////////////////////////////////////////////////////////

$main-sm-columns:       12;
$sidebar-sm-columns:    4;

///////////////////////////////////////////////////////////
// Colors
///////////////////////////////////////////////////////////

$brand-primary:         #e51e25; // red
$red:					#e51e25;
$purple: 				#41313f;
$light-purple: 			#775e73;
$black:					#231f20;
$white:					#fff;
$off-white:				#f5f3e7;
$dark-gray:				#414042;
$gray:					#939598;
$light-gray:			#aca38d;
$lightest-gray:			#e7e4d3;
$blue:					#449fac;
$light-blue: 			#bedddd;

///////////////////////////////////////////////////////////
// Main Nav
///////////////////////////////////////////////////////////

$navigation-height:115px;
$navigation-height-md:100px;
$navigation-height-sm:60px;

///////////////////////////////////////////////////////////
// Navburger
// @link https://github.com/jonsuh/hamburgers
///////////////////////////////////////////////////////////

$hamburger-padding-x                       : 15px !default;
$hamburger-padding-y                       : 15px !default;
$hamburger-layer-width                     : 30px !default;
$hamburger-layer-height                    : 3px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : $black !default;
$hamburger-layer-border-radius             : 4px !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

///////////////////////////////////////////////////////////
// webicons
///////////////////////////////////////////////////////////
$icomoon-font-path: "../fonts" !default;
$webicon-play: \e912;
$webicon-phone: \e942;
$webicon-phone-hang-up: \e943;
$webicon-display: \e956;
$webicon-mobile2: \e959;
$webicon-checkbox-checked: \ea52;
$webicon-checkbox-unchecked: \ea53;
$webicon-radio-checked: \ea54;
$webicon-radio-checked2: \ea55;
$webicon-radio-unchecked: \ea56;
$webicon-mail: \ea83;
$webicon-mail2: \ea84;
$webicon-mail3: \ea85;
$webicon-mail4: \ea86;
$webicon-mail_outline: \e0e1;
$webicon-insert_invitation: \e24f;
$webicon-keyboard_arrow_down: \e313;
$webicon-keyboard_arrow_left: \e314;
$webicon-keyboard_arrow_right: \e315;
$webicon-keyboard_arrow_up: \e316;
$webicon-close: \e5cd;
$webicon-pdf: \e902;
$webicon-talk: \e903;
$webicon-houzz: \e915;
$webicon-zoom-in: \e987;
$webicon-enlarge: \e989;
$webicon-plus: \ea0a;
$webicon-minus: \ea0b;
$webicon-info: \ea0c;
$webicon-cancel-circle: \ea0d;
$webicon-blocked: \ea0e;
$webicon-cross: \ea0f;
$webicon-checkmark: \ea10;
$webicon-checkmark-hollow: \ea11;
$webicon-arrow-up2: \ea3a;
$webicon-arrow-right2: \ea3c;
$webicon-arrow-down2: \ea3e;
$webicon-arrow-left2: \ea40;
$webicon-circle-up: \ea41;
$webicon-circle-right: \ea42;
$webicon-circle-down: \ea43;
$webicon-circle-left: \ea44;
$webicon-terminal: \ea81;
$webicon-share2: \ea82;
$webicon-google: \ea88;
$webicon-google-boxed: \ea89;
$webicon-google3: \ea8a;
$webicon-google-plus: \ea8b;
$webicon-google-plus2: \ea8c;
$webicon-google-plus3: \ea8d;
$webicon-facebook: \ea90;
$webicon-facebook-boxed: \ea91;
$webicon-instagram: \ea92;
$webicon-twitter: \ea96;
$webicon-vine: \ea97;
$webicon-rss: \ea9b;
$webicon-rss-boxed: \ea9c;
$webicon-youtube: \ea9d;
$webicon-vimeo: \eaa0;
$webicon-vimeo-boxed: \eaa1;
$webicon-flickr: \eaa3;
$webicon-flickr2: \eaa4;
$webicon-flickr-boxed: \eaa5;
$webicon-flickr4: \eaa6;
$webicon-behance: \eaa8;
$webicon-behance-boxed: \eaa9;
$webicon-dropbox: \eaae;
$webicon-github: \eab0;
$webicon-basecamp: \eab2;
$webicon-wordpress: \eab4;
$webicon-tumblr: \eab9;
$webicon-tumblr-boxed: \eaba;
$webicon-tux: \eabd;
$webicon-apple: \eabe;
$webicon-finder: \eabf;
$webicon-android: \eac0;
$webicon-reddit: \eac6;
$webicon-linkedin-boxed: \eac9;
$webicon-linkedin: \eaca;
$webicon-lastfm: \eacb;
$webicon-lastfm-boxed: \eacc;
$webicon-delicious: \eacd;
$webicon-pinterest: \ead1;
$webicon-pinterest2: \ead2;
$webicon-yelp: \ead7;
$webicon-paypal: \ead8;
$webicon-file-pdf: \eadf;
$webicon-file-openoffice: \eae0;
$webicon-file-word: \eae1;
$webicon-file-excel: \eae2;
$webicon-libreoffice: \eae3;
$webicon-up: \f0d8;
$webicon-info2: \f129;
$webicon-peace: \f25b;
$webicon-snapchat: \f2ac;
