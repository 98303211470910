
// The ribbons

.corner-ribbon{
	padding:5px 0;
	width: 200px;
	background: $black;
	position: absolute;
	top: 25px;
	left: -50px;
	text-align: center;
	line-height:1.1;
	font-family: $sans-serif;
	text-transform:uppercase;
	color: $lightest-gray;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);

	// Custom styles
	&.sticky{
	  position: fixed;
	}

	&.shadow{
	  box-shadow: 0 0 3px rgba(0,0,0,.3);
	}

	// Different positions
	// &.top-left{
	  // top: 25px;
	  // left: -50px;
	  // transform: rotate(-45deg);
	  // -webkit-transform: rotate(-45deg);
	// }

	&.top-right{
	  top: 25px;
	  right: -50px;
	  left: auto;
	  transform: rotate(45deg);
	  -webkit-transform: rotate(45deg);
	}

	&.bottom-left{
	  top: auto;
	  bottom: 25px;
	  left: -50px;
	  transform: rotate(45deg);
	  -webkit-transform: rotate(45deg);
	}

	&.bottom-right{
	  top: auto;
	  right: -50px;
	  bottom: 25px;
	  left: auto;
	  transform: rotate(-45deg);
	  -webkit-transform: rotate(-45deg);
	}


	// Colors
	&.white{background: $white; color: $black;}
	&.black{background: $black;}
	&.gray{background: $gray;}
	&.blue{background: $blue;}
	&.light-blue{background: $light-blue; color: $purple;}
	// &.green{background: #2c7;}
	// &.turquoise{background: #1b9;}
	&.purple{background: $purple;}
	// &.red{background: #e43;}
	// &.orange{background: #e82;}
	// &.yellow{background: #ec0;}
}

// bootstrap overrides
.btn, 
.comment-form input[type=submit], 
.search-form .search-submit {
	border-radius: 0;
	background: $purple;
    // font-size: em(18px);
	font-family: $sans-serif;
	color: $off-white;
	border: 1px solid $purple;
}

.btn-secondary:hover, 
.comment-form input[type=submit]:hover, 
.search-form .search-submit:hover {
    color: $off-white;
    background-color: $gray;
    border-color: $gray;
	cursor: pointer;
}

.btn-secondary.active, 
.btn-secondary:active, 
.comment-form .show>input.dropdown-toggle[type=submit], 
.comment-form input.active[type=submit], 
.comment-form input[type=submit]:active, 
.search-form .active.search-submit, 
.search-form .search-submit:active, 
.search-form .show>.dropdown-toggle.search-submit, 
.show>.btn-secondary.dropdown-toggle {
    background-color: darken($gray, 25%);
    border-color: darken($gray, 25%);
}

.btn-secondary.focus, 
.btn-secondary:focus, 
.comment-form input.focus[type=submit], 
.comment-form input[type=submit]:focus, 
.search-form .focus.search-submit, 
.search-form .search-submit:focus {
    box-shadow: 0 0 0 0 $white;
}

.btn {
	display:inline-block;
	width:auto;
	position:relative;
	padding:12px 20px;
	text-align:center;
	font-family:$font-stack;
	font-size:em(14px);
	line-height:1;
	font-weight:400;
	color:#fff;
	text-decoration:none !important;
	border:1px solid #ccc;
	border-radius: 0 !important;
	text-transform:uppercase;
	white-space:normal;
}

.btn:hover, .btn:active, .btn:visited {
	color:#fff;
	text-decoration:none !important;
}

.btn-sm {
	font-size:em(12px);
}

.btn-lg {
	font-size:em(24px);
}

.btn-default { border-color:gray; background-color:gray; }
.btn-red, .btn-red:focus { background-color:$red !important; border-color:$red !important; color:$white !important; }
.btn-purple, .btn-purple:focus { background-color:$purple !important; border-color:$purple !important; color:$white !important; }
.btn-blu, .btn-blu:focus { background-color:$blue !important; border-color:$blue !important; color:$white !important; }
.btn-dark, .btn-dark:focus { background-color:$black !important; border-color:$black !important; color:$white !important; }
.btn-hlw, .btn-hlw:focus { background-color:transparent !important; border-color:$white !important; color:$black !important; }

.btn-red:hover, .btn-red:active, .btn-red.active, 
.btn-blu:hover, .btn-blu:active, .btn-blu.active, 
.btn-purple:hover, .btn-purple:active, .btn-purple.active, 
.btn-dark:hover, .btn-dark:active, .btn-dark.active, 
.btn-hlw:hover, .btn-hlw:active, .btn-hlw.active, 
.comment-form input[type=submit]:hover  
{
	background-color:transparent !important; border-color:$gray !important; color:$gray !important; outline:0;
}

// .btn-primary, .comment-form input[type=submit] {
    // color:$white;
    // background-color:$red;
    // border-color:$red;
// }



































